import React, { useState } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';

import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import classes from './PlanCard.module.scss';

const PlanCard = ({
  price,
  description,
  ctaText,
  features = [],
  isCurrentPlan,
  actionText,
  onSelect,
  additionalPriceInfo,
  headerCta,
  headerCtaAction,
  title,
  actionButtonVariant,
  subActionOnClick,
  subActionCtaText,
  profileNameForSubscription,
  profileNameForSubText,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const [isExpanded, setIsExpanded] = useState(!isMobile);

  const toggleAccordion = () => {
    setIsExpanded(prevState => !prevState);
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleAccordion();
    }
  };

  return (
    <div className={classes.planCard}>
      <div
        className={classnames(classes.planCard__header, {
          [classes.planCard__header_expanded]: isExpanded,
        })}
        role="button"
        tabIndex="0"
        onClick={isMobile ? toggleAccordion : () => {}}
        onKeyDown={isMobile ? handleKeyPress : () => {}}
      >
        <div className={classes.planCard__headerTop}>
          {price && (
            <Typography size="32" className={classes.planCard__price} color="orange">
              {price}
            </Typography>
          )}
          {ctaText && !isMobile ? (
            <LinkButton
              rightIcon={<SpriteIcon icon="chevron_right" size={16} className={classes.expandMore} />}
              variant="text"
              onClick={e => {
                e.stopPropagation();
              }}
              styles={{ root: classes.planCard__cta }}
            >
              {t(ctaText)}
            </LinkButton>
          ) : (
            description && (
              <Typography
                size="10"
                color="secondary"
                className={classnames(classes.planCard__description, {
                  [classes.planCard__description_fullWidth]: !price,
                })}
              >
                {t(description)}
              </Typography>
            )
          )}

          {headerCta && isExpanded && (
            <div className={classes.planCard__ctaBadge}>
              <LinkButton
                onClick={e => {
                  e.stopPropagation();
                  headerCtaAction();
                }}
                variant="text"
                styles={{ root: classes.planCard__ctaBadge_ctaText }}
                rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
              >
                {t(headerCta)}
              </LinkButton>
            </div>
          )}
          {isMobile && (
            <div className={classes.planCard__mobileExpandIcon}>
              <SpriteIcon icon="chevron_right" size={16} />
            </div>
          )}
        </div>
        <div className={classes.planCard__planInfo}>
          {title && (
            <Typography size={14} weight="medium">
              {t(title)}
            </Typography>
          )}
          {additionalPriceInfo && (
            <Typography size={12} color="secondary">
              {t(additionalPriceInfo)}
            </Typography>
          )}
          {isCurrentPlan && (
            <Typography className={classes.badge} size={10} color="white">
              {t(`CURRENT_PLAN`)}
            </Typography>
          )}
        </div>
        <div>
          {profileNameForSubText && (
            <Typography size="12" color="link">
              {t(profileNameForSubText, {
                value: profileNameForSubscription,
              })}
            </Typography>
          )}
        </div>
      </div>
      {isExpanded && (
        <>
          <div className={classes.planCard__content}>
            <ul className={classes.planCard__features}>
              {features.map((feature, index) => (
                <li key={index} className={classes.planCard__features_list}>
                  <SpriteIcon icon={feature.icon} size={16} />
                  <Typography size="12">{t(feature.label)}</Typography>
                </li>
              ))}
            </ul>
            {subActionCtaText && isMobile && (
              <div className={classes.subActionCta}>
                <LinkButton
                  onClick={subActionOnClick}
                  className={classes.planCard__action}
                  variant="text"
                  rightIcon={<SpriteIcon icon="chevron_right" size="10" />}
                >
                  <Typography size="10" color="secondary">
                    {t(subActionCtaText)}
                  </Typography>
                </LinkButton>
              </div>
            )}
          </div>
          <div className={classes.planCard__footer}>
            {actionText && (
              <LinkButton
                onClick={onSelect}
                className={classes.planCard__action}
                variant={actionButtonVariant}
                rightIcon={<SpriteIcon icon="chevron_right" />}
                isV4
              >
                {t(actionText)}
              </LinkButton>
            )}
            {subActionCtaText && !isMobile && (
              <LinkButton
                onClick={subActionOnClick}
                className={classes.planCard__action}
                variant="text"
                rightIcon={<SpriteIcon icon="chevron_right" size="10" />}
              >
                <Typography size="10" color="secondary">
                  {t(subActionCtaText)}
                </Typography>
              </LinkButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PlanCard;
