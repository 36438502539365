/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';
import Drawer from 'components/uiLibrary/Drawer';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Image from 'components/uiLibrary/Image';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Testimonials from 'components/Globals/Testimonials';
import { TextButton } from 'components/uiLibrary/LinkButton';
import LanguageSelector from 'components/Globals/LanguageSelector';

import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import { useTranslation, Trans } from 'src/i18n';

import { TP } from 'constants/index';

import LogoImage from 'public/images/logo.png';

import { NavOptions } from '../NavLink';

import classes from './Menu.module.scss';

const menuTrackingData = {
  section: SECTIONS.MENU,
};

const language = { title: `${TP}.FN_LANGUAGE_LABEL` };

const MenuItem = ({ title, total, data, path, onClose, trackingData = {}, ...rest }) => (
  <div className={classes.menuItem}>
    <NavOptions
      data={data}
      total={total}
      path={path}
      title={title}
      limit={7}
      isMenu
      onClose={onClose}
      trackingData={trackingData}
      {...rest}
    />
  </div>
);

const DrawerItem = ({ title, total, data, path, onClose, trackingData = {}, ...rest }) => (
  <div className={classes.drawerItem}>
    <NavOptions
      data={data}
      total={total}
      path={path}
      title={title}
      limit={15}
      isDrawer
      onClose={onClose}
      trackingData={trackingData}
      {...rest}
    />
  </div>
);

const MenuDrawer = ({ links, onClose, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const [selectedOption, setSelectedOption] = useState(links[0]);
  const drawerRef = useRef(null);

  useScrollBlocker(true);

  const scrollToTop = () => {
    if (drawerRef.current) {
      drawerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Drawer isOpen onClose={onClose} className={classes.drawer}>
      <div ref={drawerRef}>
        <div className={classes.drawer__header}>
          <Typography size="16" weight="bold">
            {t(`${TP}.MENU`)}
          </Typography>
          <TextButton
            trackingData={{
              ...trackingData,
              component: COMPONENTS.CLOSE_CTA,
            }}
            onClick={onClose}
            leftIcon={<SpriteIcon icon="modal_close" />}
            disableUnderline
          >
            <Typography weight="bold">{t(`${TP}.CLOSE`)}</Typography>
          </TextButton>
        </div>
        <div className={classes.drawer__content}>
          <div className={classes.drawer__leftSection}>
            {links.map(link => (
              <div
                key={link.title}
                className={classnames(classes.drawer__leftSection_item, {
                  [classes.drawer__leftSection_selected]: selectedOption?.title === link?.title,
                })}
                onClick={() => {
                  track.click({
                    ...trackingData,
                    component: COMPONENTS.MENU_ITEM,
                    meta: {
                      id: link?.as,
                    },
                  });
                  setSelectedOption(link);
                  scrollToTop();
                }}
              >
                <Typography size="12">{link.title}</Typography>
                {link.total > 0 && (
                  <Typography size="11" color="secondary">
                    {link.total}
                  </Typography>
                )}
              </div>
            ))}
            <div
              className={classnames(classes.drawer__leftSection_item, {
                [classes.drawer__leftSection_selected]: selectedOption?.title === language.title,
              })}
              onClick={() => {
                track.click({
                  ...trackingData,
                  component: COMPONENTS.MENU_ITEM,
                  meta: {
                    id: 'language',
                  },
                });
                setSelectedOption(language);
                scrollToTop();
              }}
            >
              <Typography size="12">{t(language.title)}</Typography>
            </div>
          </div>
          <div className={classes.drawer__rightSection}>
            {selectedOption?.title === language.title ? (
              <div>
                <Typography size="13" weight="bold">
                  {`${t(`${TP}.m_LANGUAGES`)}`}
                </Typography>
                <LanguageSelector renderAs="content" type="menu" />
              </div>
            ) : (
              <DrawerItem onClose={onClose} trackingData={trackingData} {...selectedOption} />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const MenuModal = ({ links, onClose, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();

  return (
    <Modal isOpen onClose={onClose} styles={{ modalContainer: classes.modalContainer, modalRoot: classes.modalRoot }}>
      <div className={classes.menu}>
        <div className={classes.menu__header}>
          <TextButton
            trackingData={{
              ...trackingData,
              component: COMPONENTS.BACK_CTA,
            }}
            leftIcon={<SpriteIcon icon="arrow_back" size="14" className={classes.icon} />}
            disableUnderline
            onClick={onClose}
          >
            <Typography weight="bold">{t(`${TP}.MENU`)}</Typography>
          </TextButton>
          <div className={classes.menu__headerContent}>
            <div className={classes.menu__logoSection}>
              <Image
                src={LogoImage}
                alt="Operabase Home"
                lazy={false}
                className={classes.menu__logoImage}
                disableCloudinary
                disableNextImage
              />
              <Typography size="11">{t(`${TP}.LP_SINCE`)}</Typography>
            </div>
            <Typography
              size="11"
              textTransform="uppercase"
              weight="medium"
              color="secondary"
              className={classes.menu__headerWorks}
            >
              <Trans
                i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                ns="NS_APP_GLOBALS"
                components={{
                  ul: <ul className={classes.menu__headerWorks_list} />,
                  li: <li />,
                }}
              />
            </Typography>
          </div>
          <SpriteIcon
            icon="close"
            size="18"
            className={classes.icon}
            onClick={() => {
              track.click({
                ...trackingData,
                component: COMPONENTS.CLOSE_CTA,
              });
              onClose();
            }}
          />
        </div>
        <div className={classes.menu__content}>
          {links.map(link => (
            <MenuItem key={link.title} onClose={onClose} trackingData={trackingData} {...link} />
          ))}
        </div>
        <div className={classes.menu__footer}>
          <Typography size="12" secondaryFont>
            {t(`${TP}.TRUSTED_AND_USED_BY`)}
          </Typography>
          <div className={classes.menu__footer_logos}>
            <CustomerLogos allowShowAll={false} />
          </div>
          <Testimonials limit={1} inLine hideTitle hideBorder />
        </div>
      </div>
    </Modal>
  );
};

const Menu = ({ onClose, links }) => {
  const { isDesktop } = useDeviceTypeLayouts();

  return !isDesktop ? (
    <MenuDrawer onClose={onClose} links={links} trackingData={menuTrackingData} />
  ) : (
    <MenuModal onClose={onClose} links={links} trackingData={menuTrackingData} />
  );
};

export default Menu;
