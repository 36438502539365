import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TextButton } from 'components/uiLibrary/LinkButton';
import HeaderSearch from 'components/Globals/Layout/Header/HeaderSearch';
import AdvancedSearch from 'components/Search/AdvancedSearch';
import NotificationBanner from 'components/NotificationBanner';

import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Globals/queries';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';

import {
  TP,
  ENTITY_TYPE,
  ORGANIZATION_TYPE_SLUGS,
  ORGANIZATION_TYPE_IDS,
  BASE_PAGE_ROUTES,
  ENTITY_MAIN_TABS,
  MEDIA_TYPES_IDS,
} from 'constants/index';
import routesWithTitle from 'constants/routesWithTitle';
import { NAVIGATION_KEYS } from 'constants/header';
import { FILTER_SLUG_TYPE, FILTER_TYPES } from 'constants/filters';

import LogoImage from 'public/images/logo.png';

import HeaderLeftBlock from './HeaderLeftBlock';
import HeaderRightBlock from './HeaderRightBlock';
import HeaderBottomBlock from './HeaderBottomBlock';
import Menu from './Menu';
import ProTools from './ProTools';

import classes from './Header.module.scss';

const NAV_LINKS = {
  [NAVIGATION_KEYS.PERFORMANCES]: {
    ...routesWithTitle.PRODUCTIONS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.PRODUCTION,
      filters: {
        [FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY]]: option?.id,
      },
    }),
  },
  [NAVIGATION_KEYS.ARTISTS]: {
    ...routesWithTitle.ARTISTS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.ARTIST,
      filters: {
        [FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]]: [option],
      },
    }),
  },
  [NAVIGATION_KEYS.ORGANIZATIONS]: {
    ...routesWithTitle.ORGANIZATIONS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.ORGANIZATION,
      filters: {
        [FILTER_SLUG_TYPE[FILTER_TYPES.ORGANIZATION_TYPE]]: [option],
      },
    }),
  },
  [NAVIGATION_KEYS.MANAGERS]: {
    ...routesWithTitle.MANAGERS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.MANAGER,
      country: option,
    }),
  },
  [NAVIGATION_KEYS.VENUES]: {
    ...routesWithTitle.VENUES,
    linkProps: option => ({
      entityType: ENTITY_TYPE.ORGANIZATION,
      filters: {
        [FILTER_SLUG_TYPE[FILTER_TYPES.ORGANIZATION_TYPE]]: [
          { slug: ORGANIZATION_TYPE_SLUGS[ORGANIZATION_TYPE_IDS.VENUE] },
        ],
        [FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY]]: [option],
      },
    }),
  },
  [NAVIGATION_KEYS.SEASONS]: {
    ...routesWithTitle.SEASONS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.ORGANIZATION,
      entity: option,
      path: ENTITY_MAIN_TABS.PERFORMANCES,
    }),
  },
  [NAVIGATION_KEYS.COMPETITIONS]: {
    ...routesWithTitle.COMPETITIONS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.ORGANIZATION,
      filters: {
        [FILTER_SLUG_TYPE[FILTER_TYPES.ORGANIZATION_TYPE]]: [
          { slug: ORGANIZATION_TYPE_SLUGS[ORGANIZATION_TYPE_IDS.COMPETITION] },
        ],
        [FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY]]: [option],
      },
    }),
  },
  [NAVIGATION_KEYS.CREATORS]: {
    ...routesWithTitle.COMPOSERS,
    title: `${TP}.CREATORS`,
    linkProps: option => ({
      entityType: ENTITY_TYPE.ARTIST,
      entity: option,
    }),
  },
  [NAVIGATION_KEYS.WORKS]: {
    ...routesWithTitle.WORKS,
    linkProps: option => ({
      entityType: ENTITY_TYPE.WORK,
      entity: option,
    }),
  },
  [NAVIGATION_KEYS.WATCH_ONLINE]: {
    ...routesWithTitle.VIDEOS,
    title: `${TP}.FN_WATCH_ONLINE_VIDEOS`,
    linkProps: option => ({
      baseRoute: `${BASE_PAGE_ROUTES.VIDEOS}${BASE_PAGE_ROUTES.SEARCH}`,
      queryParams: {
        has_posters: true,
        is_valid_ls_vod: true,
        is_production_published: true,
        media_type_id: MEDIA_TYPES_IDS.LS,
        ...(option?.organizationType?.id ? { organization_id: option?.id } : { individual_id: option?.id }),
      },
    }),
  },
};

const headerTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.NAVIGATION,
};

const obLogoTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.OPERABASE_BUTTON,
};

const searchTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.GLOBAL_SEARCH,
};

const HeaderMobile = ({ homePageLinkProps, onOpenMenu, links = [], onOpenProTools, trackingData, isLoggedIn }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <>
      {!isLoggedIn && (
        <TextButton
          {...homePageLinkProps}
          isLink
          styles={{ root: classes.mobileHeader__logoSection }}
          trackingData={obLogoTrackingData}
        >
          <Image
            src={LogoImage}
            alt="Operabase Home"
            lazy={false}
            className={classes.mobileHeader__logoImage}
            disableCloudinary
            disableNextImage
          />
          <Typography size="10">{t(`${TP}.LP_SINCE`)}</Typography>
        </TextButton>
      )}
      <div className={classes.mobileHeader__sectionTop}>
        <HeaderLeftBlock onOpenMenu={onOpenMenu} onOpenProTools={onOpenProTools} trackingData={trackingData} />
        <HeaderRightBlock trackingData={trackingData} />
      </div>
      <div className={classes.mobileHeader__sectionMiddle}>
        {isLoggedIn && (
          <TextButton
            {...homePageLinkProps}
            isLink
            styles={{ root: classes.mobileHeader__logo }}
            rightIcon={<SpriteIcon icon="operabase_icon" />}
            trackingData={obLogoTrackingData}
          />
        )}
        <AdvancedSearch trackingData={searchTrackingData} />
      </div>
      <HeaderBottomBlock links={links} trackingData={trackingData} />
    </>
  );
};

const HeaderDesktop = ({ homePageLinkProps, onOpenMenu, links = [], onOpenProTools, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.header__content}>
      <div className={classes.header__sectionTop}>
        <HeaderLeftBlock trackingData={trackingData} homePageLinkProps={homePageLinkProps} />
        <HeaderRightBlock onOpenProTools={onOpenProTools} trackingData={trackingData} />
      </div>
      <div className={classes.header__sectionMiddle}>
        <TextButton
          {...homePageLinkProps}
          isLink
          styles={{ root: classes.header__logoSection }}
          trackingData={obLogoTrackingData}
        >
          <Image
            src={LogoImage}
            alt="Operabase Home"
            lazy={false}
            className={classes.header__logoImage}
            disableCloudinary
            disableNextImage
          />
          <Typography size="10" color="secondary" align="right" className={classes.header__logoSection_since}>
            {t(`${TP}.LP_SINCE`)}
          </Typography>
        </TextButton>
        <HeaderSearch />
      </div>
      <div className={classes.header__sectionBottom}>
        <HeaderBottomBlock onOpenMenu={onOpenMenu} links={links} trackingData={trackingData} />
      </div>
    </div>
  );
};

const Header = ({ isStatic, hideNotificationBanner }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const { isLoggedIn } = useAppContext();
  const { navigate, isHeaderVisible } = usePageContext();
  const { profileData } = useGetTargetProfile();
  const homePageLinkProps = navigate.getLinkProps({ onlyLinkProps: true });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProToolsOpen, setIsProToolsOpen] = useState(false);

  const { data: statsNumbers } = useQuery(queries.getHomeNumbers());
  const { data: menuItems } = useQuery(queries.getMenuItems());

  const navigationLinks = useMemo(
    () =>
      Object.entries(NAV_LINKS).map(([key, link]) => ({
        ...link,
        title: t(link.title),
        total: statsNumbers?.data?.[key]?.total || 0,
        data: menuItems?.data?.[key],
        type: key,
      })),
    [menuItems?.data, statsNumbers, t],
  );

  const handleMenu = isOpen => {
    setIsMenuOpen(isOpen);
  };

  const handleProTools = isOpen => {
    setIsProToolsOpen(isOpen);
  };

  return (
    <>
      <header
        className={classnames({
          [classes.header]: isDesktop,
          [classes.mobileHeader]: !isDesktop,
          [classes.static]: isStatic,
          [classes.hideHeader]: !isHeaderVisible,
        })}
      >
        {!isDesktop ? (
          <HeaderMobile
            homePageLinkProps={homePageLinkProps}
            onOpenMenu={() => handleMenu(true)}
            onOpenProTools={() => handleProTools(true)}
            links={navigationLinks}
            trackingData={headerTrackingData}
            isLoggedIn={isLoggedIn}
          />
        ) : (
          <HeaderDesktop
            homePageLinkProps={homePageLinkProps}
            onOpenMenu={() => handleMenu(true)}
            onOpenProTools={() => handleProTools(true)}
            links={navigationLinks}
            trackingData={headerTrackingData}
          />
        )}
      </header>
      {!hideNotificationBanner && <NotificationBanner profileId={profileData?.id} />}
      {isMenuOpen && <Menu onClose={() => handleMenu(false)} links={navigationLinks} />}
      {isProToolsOpen && <ProTools onClose={() => handleProTools(false)} />}
    </>
  );
};

export default Header;
