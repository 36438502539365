import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import classes from './Checkbox.module.scss';

const Checkbox = ({ onChange, label, checked, name, size, className, selectedClassName }) => (
  <FormControlLabel
    control={<MuiCheckbox checked={checked} size={size} />}
    label={label}
    onChange={onChange}
    name={name}
    className={classnames(classes.checkbox, className, {
      [classes.isSelected]: checked,
      [selectedClassName]: checked,
    })}
  />
);

Checkbox.defaultProps = {
  size: 'medium',
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string]).isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  size: PropTypes.string,
};

export default Checkbox;
