const USER_TOKEN_COOKIE_KEY = 'ob_access_key';
const USER_REFRESH_TOKEN_COOKIE_KEY = 'ob_refresh_token_key';
const ANONYMOUS_TOKEN_CACHE_KEY = 'ANONYMOUS_ACCESS_KEY_TOKEN';
const ACTIVE_PROFILE_ID = 'ob_active_profile_id';
const TRACKING_SESSION_KEY = 'ob_tracking_session';
const USER_COUNTRY_CODE = 'ob_country_code';
const USER_SESSION = 'ob_session';
const USER_SESSION_INFO = 'ob_user';
const PROFILE_SWITCH = 'ob_profile_switch';
const SOCIAL_LOGIN = 'ob_social_login';
const LOGIN_TYPE = 'ob_login_type';
const OB_USER_ID = 'ob_user_id';
const OB_EMAIL_ID = 'ob_email_id';
const FIRST_EDIT = 'ob_first_edit';
const IS_CHECKOUT_ACTIVE = 'is_checkout_active';
const IS_IDENTITY_VERIFICATION = 'is_identity_verification';
const IS_DISCLAIMER_POP_UP_SEEN = 'is_disclaimer_pop_up_seen';
const IS_CHECKOUT_APPEARED = 'is_checkout_appeared';
const LANGUAGE_LAST_ACCESSED = 'ob_lang_last';
const LANGUAGE_PREFERRED = 'ob_lang_pref';
const RETURNING_USER_NAME = 'ob_returning_user_name';
const OB_EVENTS_REFRESH_TOKEN = 'ob_events_refresh';
const OB_EVENTS_AUTH_TOKEN = 'ob_events_auth';
const LOGIN_TYPE_RETURNING = 'ob_login_type_returning';
const OB_UTM = 'ob_utm';
const OB_VISITOR_ID = 'visitorId'; // TODO: Should follow same nomenclature
const OB_APP_VERSION = 'ob_app_version';
const OB_VISITOR_LOCATION = 'ob_visitor_location';

const EXPIRY_TIME = {
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
  YEAR: 1000 * 60 * 60 * 24 * 365,
};

module.exports = {
  USER_TOKEN_COOKIE_KEY,
  USER_REFRESH_TOKEN_COOKIE_KEY,
  ANONYMOUS_TOKEN_CACHE_KEY,
  ACTIVE_PROFILE_ID,
  TRACKING_SESSION_KEY,
  USER_COUNTRY_CODE,
  USER_SESSION,
  USER_SESSION_INFO,
  PROFILE_SWITCH,
  SOCIAL_LOGIN,
  LOGIN_TYPE,
  OB_USER_ID,
  OB_EMAIL_ID,
  FIRST_EDIT,
  IS_CHECKOUT_ACTIVE,
  IS_IDENTITY_VERIFICATION,
  LANGUAGE_LAST_ACCESSED,
  LANGUAGE_PREFERRED,
  EXPIRY_TIME,
  IS_DISCLAIMER_POP_UP_SEEN,
  IS_CHECKOUT_APPEARED,
  RETURNING_USER_NAME,
  OB_EVENTS_REFRESH_TOKEN,
  OB_EVENTS_AUTH_TOKEN,
  LOGIN_TYPE_RETURNING,
  OB_UTM,
  OB_VISITOR_ID,
  OB_APP_VERSION,
  OB_VISITOR_LOCATION,
};
