import React from 'react';
import PlanFeatureComparison from 'components/Globals/PlanFeatureComparison';
import { SUBSCRIPTIONS_STATUSES } from 'containers/UserContainers/AccountSettingsContainer/constants';
import { useSubscription } from 'utils/hooks/useSubscription';
import { PLANS, TP } from 'constants/index';

const PLAN_DETAILS = {
  [PLANS.FREE]: {
    id: PLANS.FREE,
    label: `${TP}.FN_FREE`,
    isCurrent: true,
  },
  [PLANS.PRO]: {
    id: PLANS.PRO,
    price: '€10',
    headline: `${TP}.FN_ANNUALLY_BILLING_TEXT`,
    ctaLabel: `${TP}.m_CONTINUE`,
  },
};
export const FEATURES = [
  {
    name: `${TP}.FN_EDIT_FEAT_VISIBILITY_REPORT`,
    supportedPlanMonthly: [PLANS.FREE, PLANS.MONTHLY, PLANS.PRO],
    supportedPlanAnnually: [PLANS.FREE, PLANS.MONTHLY, PLANS.PRO],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_VISIBILITY_BOOST`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_PROFILE_INFO`,
    supportedPlanMonthly: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `CL_CONTACT_INFO`,
    supportedPlanMonthly: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_ARTIST_MANAGER`,
    supportedPlanMonthly: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_ORIGINAL_WORKS`,
    supportedPlanMonthly: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_PERFORMANCE_CREDITS`,
    supportedPlanMonthly: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.FREE, PLANS.PRO, PLANS.MONTHLY],
  },
  { name: `${TP}.FN_EDIT_FEAT_PERFORMANCE_VERIFICATION`, supportedPlanMonthly: [], supportedPlanAnnually: [PLANS.PRO] },
  {
    name: `${TP}.FN_TAB_REPERTOIRE`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_FUTURE_REPRTOIRE`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_TAB_PHOTOS`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.OPERA_ONLINE`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_TAB_REVIEWS`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_SOCIAL_MEDIA`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_TAB_BIOGRAPHY`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_WORKED_WITH_PEOPLE`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_WORKED_WITH_ORG`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
  {
    name: `${TP}.FN_EDIT_FEAT_WORKED_AT_VENUE`,
    supportedPlanMonthly: [PLANS.PRO, PLANS.MONTHLY],
    supportedPlanAnnually: [PLANS.PRO, PLANS.MONTHLY],
  },
];

const getModifiedPlans = hasMonthlySubscription => {
  if (hasMonthlySubscription) {
    return [
      {
        id: PLANS.MONTHLY,
        label: `${TP}.PAYMENTS_PRODUCT_MONTHLY`,
        isCurrent: true,
      },
      ...Object.values(PLAN_DETAILS).filter(plan => plan.id !== PLANS.FREE),
    ];
  }
  return Object.values(PLAN_DETAILS);
};

const ArtistPlanComparison = ({ onBackClickHandler, selectedTab, isOpen, isPage, onlyBenefits }) => {
  const activeSubscription = useSubscription();
  const hasMonthlySubscription =
    activeSubscription?.subscription?.product?.id === 4 &&
    activeSubscription?.status !== SUBSCRIPTIONS_STATUSES.cancelled;
  const modifiedPlans = getModifiedPlans(hasMonthlySubscription);

  return (
    <PlanFeatureComparison
      features={FEATURES}
      plans={modifiedPlans}
      onBackClickHandler={onBackClickHandler}
      selectedTab={selectedTab}
      isOpen={isOpen}
      isPage={isPage}
      onlyBenefits={onlyBenefits}
    />
  );
};

export default ArtistPlanComparison;
