/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

import useNewTracking from 'components/Globals/Analytics';
import {
  GOOGLE_OLD_TRACKING_SERVICES,
  SECTIONS,
  SUB_COMPONENTS,
  COMPONENTS,
} from 'components/Globals/Analytics/constants';
import Typography from 'components/uiLibrary/Typography';
import Button from 'components/uiLibrary/Button';

import { useTranslation } from 'src/i18n';

import { TP, BASE_PAGE_ROUTES, PAYWALL_TYPES } from 'constants/index';
import { REGISTRATION_COHORT_TYPES } from 'containers/Accounts/constants';
import { useDialogs } from 'utils/hooks/useDialogs';
// eslint-disable-next-line import/no-cycle
import { TRACK_EVENTS, PAYWALL_EVENT_IDS } from 'utils/tracking';
import usePageContext from 'utils/hooks/usePageContext';
import { VWO_GOAL_ID, vwoTrackGoal } from 'utils/vwo';

import classes from './PaywallNudges.module.scss';

const PROFILE_TYPE = {
  AUDIENCE: 'audience',
  ARTIST: 'artist',
  JOURNALIST: 'journalist',
  AOS: 'aos',
  AGENCY: 'agency',
  EDUCATION_ACADEMIA: 'education_academia',
};

const CohortTypeSelector = ({ isLoggedIn, setPaywallType, t, isHardWall, paywallType }) => {
  const { setIsLoginDialog, setIsNewUser } = useDialogs();
  const { navigate } = usePageContext();
  const track = useNewTracking();

  const userTypes = [
    { type: `${TP}.PROAUDIENCE`, id: REGISTRATION_COHORT_TYPES.AUDIENCE, profile_type: PROFILE_TYPE.AUDIENCE },
    {
      type: `${TP}.LP_ARTISTS_AND_PRO`,
      id: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
      profile_type: PROFILE_TYPE.ARTIST,
    },
    {
      type: `${TP}.LP_FOR_AOS`,
      id: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
      profile_type: PROFILE_TYPE.AOS,
    },
    {
      type: `${TP}.LP_FOR_MANAGERS`,
      id: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
      profile_type: PROFILE_TYPE.AGENCY,
    },
    {
      type: `${TP}.FOR_JOURNALISTS`,
      id: REGISTRATION_COHORT_TYPES.JOURNALIST_AND_MEDIA,
      profile_type: PROFILE_TYPE.JOURNALIST,
    },
    {
      type: `${TP}.LP_FOR_EDU`,
      id: REGISTRATION_COHORT_TYPES.EDUCATION_AND_ACADEMIA,
      profile_type: PROFILE_TYPE.EDUCATION_ACADEMIA,
    },
  ];

  const handleRegister = useCallback(
    (userTypeId, profile_type) => {
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          data: {
            id: PAYWALL_EVENT_IDS.PAYWALL_COHORT_CLICK,
            register_type: profile_type,
            actionType: paywallType,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );

      track.click({
        section: SECTIONS.PAYWALL,
        component: COMPONENTS.COHART_SELECTION,
        subComponent: SUB_COMPONENTS.LOGIN_CTA,
        meta: {
          actionType: paywallType,
          register_type: profile_type,
        },
      });

      if (isLoggedIn) {
        const linkProps = navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.REGISTER,
          queryParams: { as: userTypeId },
        });
        navigate.to(linkProps);
        setPaywallType('');
      } else {
        setIsNewUser(true);
        setIsLoginDialog({
          isOpen: true,
          as: userTypeId,
          source: isHardWall ? PAYWALL_TYPES.HARD_WALL : PAYWALL_TYPES.SOFT_WALL,
        });
      }
      vwoTrackGoal(VWO_GOAL_ID().PAYWALL_IGNORE);
    },
    [track, paywallType, isLoggedIn, navigate, setPaywallType, setIsNewUser, setIsLoginDialog, isHardWall],
  );

  return (
    <div className={classes.cohortTypeSelector}>
      <div className={classes.cohortTypeSelector__header}>
        <span className={classes.separatorLine}></span>
        <Typography size={14} weight="medium" className={classes.titleCohort}>
          {t('NUDGE_TITLE_MAIN')}
        </Typography>
        <span className={classes.separatorLine}></span>
      </div>
      <div className={classes.cohortTypeSelector__buttonsWrap}>
        {userTypes.map((userType, index) => (
          <Button
            variant="secondary"
            key={index}
            shape="rectangle"
            onClick={() => handleRegister(userType.id, userType.profile_type)}
          >
            {t(userType.type)}
          </Button>
        ))}
      </div>
    </div>
  );
};

const PaywallNudges = ({ isLoggedIn, setPaywallType, isHardWall, paywallType }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.PaywallNudges}>
      <CohortTypeSelector
        isLoggedIn={isLoggedIn}
        setPaywallType={setPaywallType}
        paywallType={paywallType}
        t={t}
        isHardWall={isHardWall}
      />
    </div>
  );
};

export default PaywallNudges;
