import React, { useMemo } from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import MediaCard from 'components/uiLibrary/MediaCard';
import EntityName from 'components/Globals/EntityName';
import { useStickyContext } from 'components/Globals/Layout/StickyContainer';
import MapView, { MARKER_SIZES } from 'components/Globals/MapView';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import SeparatorList, { SEPARATOR_TYPES } from 'components/uiLibrary/SeparatorList';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { getMediaSource } from 'utils/media';
import { getLocation } from 'utils/globals';

import { getCreatorsName } from 'utils/works';
import { useTranslation } from 'src/i18n';

import { TP, ENTITY_TYPE, ENTITY_TABS_TITLE } from 'constants/index';

import classes from './EntityCard.module.scss';
import { SUB_COMPONENTS } from '../Analytics/constants';

const Content = ({
  featured,
  large,
  typographyProps,
  entity,
  entityType,
  eventType,
  styles,
  trackingData,
  withNewTabIcon,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isSticky = useStickyContext();
  const { mainPath } = usePageContext();

  const { nameProps, subtextProps, labelProps } = useMemo(
    () => ({
      nameProps: {
        variant: large ? 'h1' : 'p',
        size: large ? 32 : 14,
        weight: 'bold',
        color: 'primary',
        inline: large,
        ...(typographyProps?.name || {}),
      },
      subtextProps: {
        variant: large ? 'span' : 'p',
        size: large ? 18 : 12,
        italic: featured,
        color: 'secondary',
        ...(typographyProps?.subtext || {}),
      },
      labelProps: {
        weight: 'medium',
        color: 'primary',
        ...(typographyProps?.label || {}),
      },
    }),
    [typographyProps, featured, large],
  );

  const { label, subtext } = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST: {
        const deathYear = entity?.personal?.death?.year;
        const birthYear = entity?.personal?.birth?.year;

        return {
          label: deathYear && birthYear ? `${birthYear}-${deathYear}` : '',
          subtext:
            entity?.professions?.length > 0 ? (
              <SeparatorList
                separator={SEPARATOR_TYPES.BULLET}
                data={entity?.professions?.map(item => item?.profession?.name || item?.name)}
              />
            ) : (
              entity?.headline
            ),
        };
      }
      case ENTITY_TYPE.ORGANIZATION: {
        return {
          label: entity?.organizationType?.name,
          subtext: getLocation(entity, { includeAreas: false }),
        };
      }
      case ENTITY_TYPE.WORK: {
        return {
          label: entity?.workType?.name,
          subtext: (
            <SeparatorList
              data={getCreatorsName(entity, false)?.map(composer => (
                <EntityName
                  name={composer?.name}
                  entityType={composer.entityType}
                  entity={{ id: composer?.id, name: composer?.name }}
                  eventType={eventType}
                  trackingData={trackingData}
                  isRaw
                />
              ))}
            />
          ),
        };
      }
      case ENTITY_TYPE.MANAGER: {
        return {
          label: entity?.label || `${TP}.M_AGENCY`,
        };
      }
      case ENTITY_TYPE.AGENCY: {
        return {
          label: entity?.label || `${TP}.M_AGENCY`,
        };
      }
      default: {
        return {};
      }
    }
  }, [entity, entityType, eventType, trackingData]);

  const tabName = useMemo(() => {
    if (!large || !mainPath) {
      return '';
    }

    const activeTab = ENTITY_TABS_TITLE?.[entityType]?.[mainPath];

    if (activeTab) {
      return ` - ${t(activeTab)}`;
    }

    return '';
  }, [t, entityType, large, mainPath]);

  return (
    <>
      <Typography
        className={classnames(classes.card_name, styles?.root)}
        {...nameProps}
        {...(isSticky && { variant: 'p' })}
      >
        <Typography
          className={classnames(classes.card_title, styles?.title, { [classes.card_stickyTitle]: isSticky })}
          {...nameProps}
          variant="span"
        >
          <EntityName entityType={entityType} entity={entity} eventType={eventType} isRaw trackingData={trackingData} />
          {tabName}
          {withNewTabIcon && <SpriteIcon icon="open_in_new" className={classes.iconOpenNewTab} size={16} />}
        </Typography>
        <br />
        {subtext && (
          <Typography
            {...subtextProps}
            className={classnames(classes.card_subtext, styles?.subtext, { [classes.card_subtext__sticky]: isSticky })}
          >
            {subtext}
          </Typography>
        )}
      </Typography>
      {label && (
        <Typography
          {...subtextProps}
          {...labelProps}
          className={classnames(classes.card_label, { [classes.card_label__sticky]: isSticky })}
        >
          {large && label && subtext && ' | '}
          {t(label)}
        </Typography>
      )}
    </>
  );
};

const Card = ({
  className,
  entityType,
  entity,
  src,
  large,
  featured,
  typographyProps,
  imgProps,
  eventType,
  hideMedia,
  styles,
  trackingData,
  onRemove,
  withNewTabIcon,
}) => {
  const { mainPath } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const isMobileOverViewPage = useMemo(() => isMobile && mainPath === null, [isMobile, mainPath]);
  const isMapShown = useMemo(() => entity?.lat && entity?.lon, [entity?.lat, entity?.lon]);

  const dimensionStyles = useMemo(() => {
    if (!imgProps?.width || hideMedia) {
      return null;
    }

    const BORDER_FIX = 2;
    const imageWrapperWidth = imgProps?.width + BORDER_FIX;

    return {
      image: {
        ...(imgProps?.height && { height: imgProps?.height + BORDER_FIX }),
        width: imageWrapperWidth,
      },
      content: {
        width: `calc(100% - ${imageWrapperWidth}px)`,
      },
    };
  }, [imgProps]);

  let content;

  if (src) {
    content = <Image src={src} {...imgProps} />;
  } else if (isMapShown) {
    content = (
      <MapView
        latitude={entity?.lat}
        longitude={entity?.lon}
        label={entity?.name}
        width={imgProps?.width}
        height={imgProps?.height}
        zoom={10}
        markerSize={MARKER_SIZES.TINY}
        withoutLink
      />
    );
  } else {
    content = <ImageAvatar type="V4" name={entity?.name} width={34} height={47} fontSize={14} />;
  }

  return (
    <div
      className={classnames(classes.card, {
        [classes.large]: large,
        [classes.cardOverview]: isMobileOverViewPage,
        [classes.card__withCloseIcon]: !!onRemove,
        [className]: !!className,
      })}
    >
      {!hideMedia && (
        <div className={classes.image} style={dimensionStyles?.image}>
          {content}
        </div>
      )}
      <div
        className={classnames(classes.details, {
          [classes.cardOverview__details]: isMobileOverViewPage,
          [classes.mediaHidden]: !!hideMedia,
        })}
        style={dimensionStyles?.content}
      >
        <Content
          entityType={entityType}
          entity={entity}
          typographyProps={typographyProps}
          featured={featured}
          large={large}
          eventType={eventType}
          styles={styles}
          trackingData={trackingData}
          withNewTabIcon={withNewTabIcon}
        />
      </div>
      {onRemove && <SpriteIcon icon="modal_close" size={16} onClick={onRemove} />}
    </div>
  );
};

const EntityCard = ({
  className,
  entityType,
  entity,
  pro,
  height,
  width,
  typographyProps,
  preload = false,
  featured = false,
  large = false,
  withoutLink = false,
  hideMedia = false,
  onClick,
  eventType,
  isLinkShallow,
  openNewTab,
  withNewTabIcon = false,
  transformations,
  noFollow = false,
  trackingData = {},
  styles = {},
  skipClickTracking,
  onRemove,
}) => {
  const { navigate } = usePageContext();
  const transformationsDimensions = useMemo(() => {
    if (transformations) {
      return transformations;
    }
    if (entityType === ENTITY_TYPE.ARTIST) {
      return TRANSFORMATIONS.PROFILE_IMAGE;
    }
    return TRANSFORMATIONS.ORG_LOGO;
  }, [entityType, transformations]);

  const organisationType = entity?.organizationType?.slug;

  const completeTrackingData = useMemo(
    () => ({
      entityId: entity?.id,
      entityName: entity?.name,
      entityType,
      ...trackingData,
      meta: {
        ...(entity?.source_tag && { sourceTag: entity.source_tag }),
        ...(organisationType && { organisationType }),
        ...trackingData.meta,
      },
    }),
    [entity?.id, entity?.name, entityType, trackingData, organisationType, entity?.source_tag],
  );

  const imgProps = useMemo(
    () => ({
      transformations: transformationsDimensions,
      ...(!featured && { height: large ? 50 : 45 }),
      ...(!featured && { width: large ? 36 : 32 }),
      ...(height && { height }),
      ...(width && { width }),
      disableNextImage: true,
      useIntersectionObserver: true,
      preload,
      alt: entity?.name,
      title: entity?.name,
    }),
    [transformationsDimensions, featured, large, height, width, preload, entity?.name],
  );

  const linkProps = navigate.getLinkProps({
    entityType,
    entity,
    pro,
    onlyLinkProps: true,
    ...(noFollow && { rel: 'nofollow' }),
  });

  const src = getMediaSource({
    id: entity?.id,
    file: { urls: entity?.image || entity?.logo },
  });

  if (featured) {
    return (
      <MediaCard
        src={src}
        imgProps={imgProps}
        linkProps={linkProps}
        openNewTab={openNewTab}
        trackingData={completeTrackingData}
      >
        <Content
          entityType={entityType}
          entity={entity}
          typographyProps={typographyProps}
          eventType={eventType}
          trackingData={completeTrackingData}
          withNewTabIcon={openNewTab && withNewTabIcon}
        />
      </MediaCard>
    );
  }

  if (withoutLink) {
    // TODO: check if this needs trackingData
    return (
      <Card
        entityType={entityType}
        entity={entity}
        src={src}
        large={large}
        featured={featured}
        typographyProps={typographyProps}
        imgProps={imgProps}
        className={classnames(classes.card_fullWidth, className)}
        eventType={eventType}
        isLinkShallow={isLinkShallow}
        styles={styles}
        trackingData={completeTrackingData}
        onRemove={onRemove}
      />
    );
  }

  return (
    <LinkButton
      styles={{ root: classnames(classes.card_link, { [className]: !!className }) }}
      variant="native"
      onClick={onClick}
      {...(openNewTab && { target: '_blank' })}
      {...linkProps}
      isLink
      shallow={isLinkShallow}
      trackingData={
        completeTrackingData
          ? {
              ...completeTrackingData,
              subComponent: completeTrackingData.subComponent || SUB_COMPONENTS.NAVIGATE_CTA,
            }
          : completeTrackingData
      }
      skipTracking={skipClickTracking}
    >
      <Card
        entityType={entityType}
        entity={entity}
        src={src}
        large={large}
        featured={featured}
        typographyProps={typographyProps}
        imgProps={imgProps}
        eventType={eventType}
        hideMedia={hideMedia}
        trackingData={completeTrackingData}
        withNewTabIcon={openNewTab && withNewTabIcon}
      />
    </LinkButton>
  );
};

export default EntityCard;
