import queries from 'containers/Accounts/queries';

import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useQuery } from 'utils/react-query';

import { STEP_VALUES, STEPS_LOGIN } from 'constants/index';

const useClaimAccess = ({ onSuccess } = {}) => {
  const userData = useUserData();
  const { setIsLoginDialog } = useDialogs();

  const { data: registrations } = useQuery(
    queries.getRegistrations({
      userId: userData?.id,
      queryConfig: { enabled: Boolean(userData?.id) },
    }),
  );

  const enabledContinue =
    registrations?.data.length === 1 && registrations?.data.some(registration => !registration?.entities?.profile?.id);

  const claimNewProfileHandler = () => {
    if (enabledContinue) {
      setIsLoginDialog({ isOpen: true, step: STEP_VALUES[STEPS_LOGIN.REGISTRATION_UPDATE] });
    } else {
      setIsLoginDialog({ isOpen: true, step: STEP_VALUES[STEPS_LOGIN.CLAIM_NEW_PROFILE] });
    }

    // NOTE: Safely call onSuccess if it exists
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  return { claimNewProfileHandler };
};

export default useClaimAccess;
