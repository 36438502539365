/* eslint-disable consistent-return */
// NOTE: URL to test analytics: http://localhost:3000/v4/analytics/en

import React, { createContext, useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line import/no-cycle
import usePageContext from 'utils/hooks/usePageContext';
import isEmpty from 'lodash-es/isEmpty';
import { getCookie } from 'utils/cookie';
import { USER_COUNTRY_CODE } from 'constants/cookieConstants';
import useAppContext from 'utils/hooks/useAppContext';
import { useTargetProfileSubscription, useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import useCampaignAttributionDetails from 'utils/hooks/useCampaignAttributionDetails';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import { ENTITY_TYPE } from 'constants/index';
import { sentryCaptureMessage } from 'utils/globals/sentry';
import {
  ANALYTICS_SERVICES,
  EVENT_TYPES,
  PAYWALL_SECTION_MAP,
  COMPONENTS,
  SECTIONS,
} from 'components/Globals/Analytics/constants';
import {
  getHumanReadableEventName,
  removeEmptyValues,
  generateSessionId,
  isInternalUser,
  profileAccessForAnalytics,
} from './utils';
import usePageInfo from './hooks/usePageInfo';
import useLegacyEvents from './useLegacyEvents';
import EventQueueManager from './EventQueueManager';

const isProductionEnv = process.env.APP_ENV === 'production';
const shouldEnableTracking = process.env.EVENTS_TRACKING_ENABLED === 'true';
const EVENT_TYPE_COLLECTIONS = [
  EVENT_TYPES.CLICK,
  EVENT_TYPES.IMPRESSION,
  EVENT_TYPES.SEARCH,
  EVENT_TYPES.ACTION_CALL_BACK,
];

const noop = props => {
  if (shouldEnableTracking) {
    sentryCaptureMessage('Missing AnalyticsProvider!', {
      extra: props,
    });
  }
};

const initialContext = {
  pageView: noop,
  click: noop,
  impression: noop,
  search: noop,
  actionCallback: noop,
  closePaywall: noop,
};

const AnalyticsContext = createContext(initialContext);

export const AnalyticsProvider = ({ children, pageCategory, pageType }) => {
  const pageRef = useRef({ pageCategory, pageType });
  const {
    setPaywallType,
    isTrustedBot,
    visitorId,
    requestId,
    setAppUsageData,
    hasFingerprintFailed,
    setPaywallData,
  } = useAppContext();
  const { permissions, language, entity, pathname } = usePageContext();
  const { profileData } = useGetTargetProfile();
  const { data: profileAccesses } = useProfileAccesses();
  const profileAccessToSend = profileAccessForAnalytics(profileAccesses?.data);
  const isTrackingEnabled = shouldEnableTracking && !pathname.startsWith('/v4/') && !isTrustedBot;
  const pageInfo = usePageInfo();
  const activeProfileSubscriptions = useTargetProfileSubscription();
  const campaignAttribution = useCampaignAttributionDetails();

  const isPerformancePage = pageInfo?.pageEntityType === ENTITY_TYPE.PRODUCTION && pageInfo.filters?.date_from;

  const commonDataDependencies = [
    language,
    profileData?.id,
    permissions?.isAdmin,
    permissions?.userData?.id,
    requestId,
    visitorId,
    activeProfileSubscriptions?.[0]?.subscription?.product?.id,
  ];

  useEffect(() => {
    pageRef.current = {
      pageCategory: pageCategory || pageRef.current.pageCategory,
      pageType: pageType || pageRef.current.pageType,
    };
  }, [pageCategory, pageType]);

  const handleAPIResponse = apiResponse => {
    const { paywallType, usage, thresholds, wallType, percent } = apiResponse?.body || {};
    if (paywallType) {
      setPaywallType(paywallType);
      setPaywallData({ wallType, percent });
    }

    if (usage || thresholds) {
      setAppUsageData(prev => ({
        ...prev,
        ...(usage && { usage }),
        ...(thresholds && { thresholds }),
      }));
    }
  };

  const eventQueue = useMemo(
    () =>
      new EventQueueManager({
        batchSize: 20,
        apiCallback: handleAPIResponse,
        defaultEnabledServices: [ANALYTICS_SERVICES.GOOGLE, ANALYTICS_SERVICES.ARTS_CONSOLIDATED],
      }),
    // NOTE: Run this code only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!isTrackingEnabled) {
      return;
    }

    eventQueue.init();
    // NOTE: Run this code only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryCode = () => getCookie(USER_COUNTRY_CODE);

  useEffect(() => {
    if (!isTrackingEnabled) {
      return;
    }
    const initializeAnalyticsWindow = () => {
      if (typeof window !== 'undefined' && window.analyticsWindow) {
        eventQueue.updateTestingWindow(window.analyticsWindow);
      }
    };

    const handleLoad = () => {
      initializeAnalyticsWindow();
    };

    // NOTE: Check if window is already loaded
    if (typeof window !== 'undefined' && window.analyticsWindow) {
      initializeAnalyticsWindow();
    } else {
      // NOTE: Listen for load event or any other relevant event
      window.addEventListener('load', handleLoad);

      // NOTE: Clean up function to remove event listener
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
    // NOTE: Empty dependency array ensures this runs once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isInternalUserValue = permissions?.isAdmin;

    if (!isInternalUserValue && isProductionEnv) {
      isInternalUserValue = isInternalUser(permissions?.userData?.email);
    }
    const commonData = removeEmptyValues({
      isInternalUser: isInternalUserValue,
      requestId: requestId || null,
      visitorId,
      language,
      userSubscriptionProductId: activeProfileSubscriptions?.[0]?.subscription?.product?.id,
      pageAccesses: profileAccessToSend,
      userActiveProfileId: profileData?.id,
      userId: permissions?.userData?.id,
      buildNumber: process.env.BUILD_NUMBER,
      domain: typeof window !== 'undefined' ? window.location?.hostname : null,
      sessionId: generateSessionId(),
      events: [],
      ...campaignAttribution,
    });

    const googleAnalyticsCommonData = {
      reqCountryCode: getCountryCode(),
      userId: permissions?.userData?.id,
      userSubscriptionStatus: permissions?.activeProfile?.subscriptionStatus,
      userSubscriptionName: activeProfileSubscriptions?.[0]?.subscription?.name,
      userSubscriptionDescription: activeProfileSubscriptions?.[0]?.subscription?.description,
      isFingerprintEnabled: !hasFingerprintFailed,
    };

    eventQueue.updateCommonData(commonData);
    eventQueue.updateGAcommonData(googleAnalyticsCommonData);
  }, commonDataDependencies);

  useEffect(() => {
    if (!isTrackingEnabled) {
      return;
    }

    const handleBeforeUnload = () => {
      eventQueue.flush();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      // NOTE: This is not running - Check if this is needed
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const trackEvent = (eventType, data, options) => {
    if (eventType !== EVENT_TYPES.PAGE_VIEW && isEmpty(data)) {
      return;
    }

    const { services, forceFlush = false } = options || {};

    const isGoogleOldOnly = services?.length === 1 && services?.[0] === ANALYTICS_SERVICES.GOOGLE_OLD;

    // NOTE: Tracking is disabled and event is not old GA event
    if (!isTrackingEnabled && !isGoogleOldOnly) {
      return;
    }

    const { pageCategory: customPageCategory, pageType: customPageType, ...trackedData } = data || {};
    const trackedPageCategory = customPageCategory || pageRef.current.pageCategory;
    const trackedPageType = customPageType || pageRef.current.pageType;
    pageRef.current = { pageCategory: trackedPageCategory, pageType: trackedPageType };

    let eventName = '';
    if (!isGoogleOldOnly && eventType === EVENT_TYPES.PAGE_VIEW) {
      eventName = getHumanReadableEventName(eventType, {
        pageCategory: trackedPageCategory,
        pageType: trackedPageType,
      });
    }

    if (!isGoogleOldOnly && EVENT_TYPE_COLLECTIONS.includes(eventType)) {
      eventName = getHumanReadableEventName(eventType, {
        entityType: trackedData.entityType,
        section: trackedData.section,
        component: trackedData.component,
        subComponent: trackedData.subComponent,
      });
    }

    const metaPayload = removeEmptyValues({
      ...trackedData,
      eventType,
      eventName,
      ...pageInfo,
      pageCategory: trackedPageCategory,
      pageType: trackedPageType,
      // NOTE: In case of Performance page, send date in meta
      ...(isPerformancePage ? { meta: { performanceDate: pageInfo.filters.date_from, ...data?.meta } } : {}),
      pageEntitySubscriptionStatus: (entity && entity?.subscriptionStatus) || '',
      timestamp: new Date().toISOString(),
    });

    if (eventType !== EVENT_TYPES.PAGE_VIEW) {
      delete metaPayload.referrer;
    }

    eventQueue.addEvent(metaPayload, {
      forceFlush,
      services,
    });
  };

  const track = useMemo(
    () => ({
      pageView: (meta, options) => {
        if (!isTrackingEnabled) {
          return;
        }
        trackEvent(EVENT_TYPES.PAGE_VIEW, meta, options);
      },
      click: (meta, options) => {
        trackEvent(EVENT_TYPES.CLICK, meta, options);
      },
      impression: (meta, options) => {
        if (!isTrackingEnabled) {
          return;
        }
        trackEvent(EVENT_TYPES.IMPRESSION, meta, options);
      },
      search: (meta, options) => {
        if (!isTrackingEnabled) {
          return;
        }
        trackEvent(EVENT_TYPES.SEARCH, meta, options);
      },
      actionCallback: (meta, options) => {
        if (!isTrackingEnabled) {
          return;
        }
        // NOTE: Event type should be one of the CUSTOM_EVENT_TYPES
        trackEvent(EVENT_TYPES.ACTION_CALL_BACK, meta, options); // NOTE: registrationSuccess, loginSuccess, logoutSuccess, paymentSuccess, paymentFailure
      },
      closePaywall: paywallType => {
        if (!isTrackingEnabled) {
          return;
        }

        // NOTE: these exact section & component is used in backend to determine if paywall is ignored
        const meta = {
          section: paywallType ? PAYWALL_SECTION_MAP[paywallType] : SECTIONS.PAYWALL,
          component: COMPONENTS.CLOSE_CTA,
        };

        const options = {
          forceFlush: true,
        };

        trackEvent(EVENT_TYPES.CLICK, meta, options);
      },
    }),
    // NOTE: Run this code only when pageInfo changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageInfo],
  );

  useLegacyEvents(track);

  useEffect(() => {
    if (pageCategory && pageType) {
      track.pageView();
    }
    // NOTE: Run this code only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo]);

  return <AnalyticsContext.Provider value={track}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContext;
