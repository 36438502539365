/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';
import dynamic from 'next/dynamic';

import useNewTracking from 'components/Globals/Analytics';
import {
  GOOGLE_OLD_TRACKING_SERVICES,
  SECTIONS,
  SUB_COMPONENTS,
  COMPONENTS,
} from 'components/Globals/Analytics/constants';
import Menu from 'components/uiLibrary/Menu';
import Drawer from 'components/uiLibrary/Drawer';
import Logout from 'components/Globals/ProfileDropdown/Logout';
import IconCard from 'components/uiLibrary/IconCard';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import ProfileMenuButton from 'components/Globals/ProfileDropdown/ProfileMenuButton';
import EntityCard from 'components/Globals/EntityCard';

import queries from 'containers/Accounts/queries';

import { route } from 'constants/routes';
import { TP, ENTITY_TYPE, TABS_TO_SHOW, BASE_PAGE_ROUTES, PROFILE_ACCESS_REQUEST_STATUSES } from 'constants/index';

import { showNotificationIcon, getUniqueProfiles, getAllProfilesWithPending } from 'utils/profileDropdown';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useUserData, useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import usePermissions from 'utils/permissions';
import useClaimAccess from 'utils/hooks/useClaimAccess';
import usePageContext from 'utils/hooks/usePageContext';
import { TRACK_EVENTS, PROFILE_DROPDOWN_EVENT_IDS } from 'utils/tracking';

import { useTranslation, Router } from 'src/i18n';

import classes from './ProfileDropdown.module.scss';

const LanguageSelector = dynamic(() => import('components/Globals/LanguageSelector'));

const trackingData = {
  section: SECTIONS.PROFILE_DROPDOWN,
};

export const useHandleProfileCardClick = (userData, tracking = {}) => {
  const { navigate } = usePageContext();
  const track = useNewTracking();
  const { data: registrations, refetch: refetchRegistration } = useQuery(
    queries.getRegistrations({
      userId: userData?.id,
      queryConfig: { enabled: !!userData?.id },
    }),
  );

  const handleProfileCardClick = useCallback(
    (profile, e, isPending) => {
      e.preventDefault();
      if (profile?.validationStatus?.id === 5) {
        return;
      }
      const activeRegistration = registrations?.data?.find(
        registration => registration?.entities?.profile?.id === profile?.id && Number(registration?.step) < 8,
      );

      const disclaimerNotAccepted = registrations?.data?.some(
        registration =>
          registration?.entities?.profile?.id === profile?.id &&
          Number(registration?.step) >= 8 &&
          !registration?.status?.has_accepted_disclaimer,
      );
      if (
        activeRegistration?.id &&
        profile?.entityType === ENTITY_TYPE.ARTIST &&
        Number(activeRegistration?.step) !== 1.5 &&
        !activeRegistration?.entities?.profile?.professions?.length
      ) {
        navigate.to(
          navigate.getLinkProps({
            baseRoute: BASE_PAGE_ROUTES.REGISTER,
            queryParams: { id: activeRegistration?.id },
          }),
        );
      } else {
        navigate.to(
          navigate.getLinkProps({
            entity: profile,
            entityType: profile?.entityType === 'agency' ? ENTITY_TYPE.MANAGER : profile?.entityType,
            edit: !isPending && !disclaimerNotAccepted,
            pro: false,
            onlyLinkProps: true,
          }),
        );
      }
      track.click({
        ...tracking,
        component: COMPONENTS.ALL_PROFILES_LIST,
        subComponent: SUB_COMPONENTS.VIEW_CTA,
      });
    },
    [track, tracking, registrations?.data, navigate],
  );

  return { handleProfileCardClick, refetchRegistration };
};

const BackHeader = ({ title, hideBackButton, handleBack }) => (
  <div className={classes.backHeader}>
    {!hideBackButton && (
      <SpriteIcon className={classes.backHeader_backBtn} icon="arrow_back" size={16} onClick={handleBack} />
    )}
    <Typography size={16} weight="bold" className={classes.backHeader__title}>
      {title}
    </Typography>
  </div>
);

const ActiveContent = ({ activeTab, profileDropdownContent, hideBackButton, handleBack }) => {
  const { t } = useTranslation();
  const getBackHeader = title => <BackHeader title={title} hideBackButton={hideBackButton} handleBack={handleBack} />;

  const tabContentMap = useMemo(
    () => ({
      [TABS_TO_SHOW.LANGUAGE_SELECTOR]: {
        title: t(`${TP}.SELECT_LANGUAGE`),
        content: (
          <div className={classes.languageSelectorWrapper}>
            <LanguageSelector renderAs="content" isProfileDropdown />
          </div>
        ),
      },
    }),
    [t],
  );

  const { title, content } = useMemo(() => tabContentMap[activeTab] || { title: '', content: profileDropdownContent }, [
    activeTab,
    profileDropdownContent,
    tabContentMap,
  ]);

  return (
    <div className={classes.profileDropDownMain}>
      {title && getBackHeader(title)}
      {content}
    </div>
  );
};

export const ProfileCardList = ({
  profileAccesses,
  handleProfileCardClick,
  styles = {
    root: '',
  },
  withoutLink = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const determineTargetEntity = profile => {
    if (profile?.organization && Object.keys(profile.organization).length > 0) {
      return { ...profile.organization, entityType: ENTITY_TYPE.ORGANIZATION };
    }
    if (profile?.agency && Object.keys(profile.agency).length > 0) {
      return { ...profile.agency, entityType: ENTITY_TYPE.AGENCY };
    }
    if (profile?.profile || profile) {
      const targetProfile = profile?.profile || profile;
      return { ...targetProfile, entityType: ENTITY_TYPE.ARTIST };
    }
    return null;
  };

  return (
    <>
      {profileAccesses?.map((profile, index) => {
        const targetEntity = determineTargetEntity(profile);

        return (
          <div
            className={classnames(classes.profileDropdown__allAccessedProfiles__card, styles.root, {
              [classes.noCursor]: targetEntity?.validationStatus?.id === 5,
            })}
            key={`${profile?.slug}-${index}`}
            onClick={e => handleProfileCardClick(targetEntity, e, profile?.isPending)}
          >
            <div className={classes.profileDropdown__allAccessedProfiles__card__content}>
              <EntityCard
                entity={targetEntity}
                entityType={targetEntity.entityType === 'agency' ? ENTITY_TYPE.MANAGER : targetEntity.entityType}
                withoutLink={withoutLink || targetEntity?.validationStatus?.id === 5}
                onClick={e => handleProfileCardClick(targetEntity, e, profile?.isPending)}
              />
              {profile?.isPending && (
                <div className={classes.pendingTag}>
                  <Typography size={10} weight="medium" italic>
                    {t(`${TP}.ACCESS_PENDING`)}
                  </Typography>
                </div>
              )}
            </div>
            {!profile?.isPending && <SpriteIcon icon="chevron_right" />}
          </div>
        );
      })}
    </>
  );
};

const ProfileDropdown = ({ hideActions = false, onClose, currentActiveTab = '', hideBackButton = false }) => {
  const router = useRouter();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useNewTracking();
  const { isDesktop } = useDeviceTypeLayouts();
  const [profileDropDownState, setProfileDropDownState] = useState(null);
  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const [activeSection, setActiveSection] = useState(null);
  const { profileData } = useGetTargetProfile();
  const userData = useUserData();
  const { handleProfileCardClick, refetchRegistration } = useHandleProfileCardClick(userData, trackingData);
  const { isAdmin } = usePermissions(profileData?.id);
  const queryEnabled = !!userData?.id && Boolean(profileDropDownState);

  const { data: allPendingProfiles } = useQuery(
    queries.getCurrentUserRequestAccess({
      user_id: userData?.id,
      status: [PROFILE_ACCESS_REQUEST_STATUSES.PENDING],
      limit: 1000,
      queryConfig: {
        enabled: queryEnabled,
        select: response => getUniqueProfiles(response?.data?.data || [], userData?.id),
      },
    }),
  );

  const hasRegistrationRoute = router.route === route.REGISTER;
  const showNotification = showNotificationIcon(profileData) && !hasRegistrationRoute;
  const { data: profileAccesses, ownerProfile } = useProfileAccesses();

  const allProfileWithPending = getAllProfilesWithPending(profileAccesses?.data, allPendingProfiles);

  const onSuccessHandler = () => {
    setProfileDropDownState(null);
  };

  const { claimNewProfileHandler } = useClaimAccess({ onSuccess: onSuccessHandler });

  useEffect(() => {
    if (profileDropDownState) {
      refetchRegistration();
    }
  }, [userData, profileDropDownState, refetchRegistration]);

  const { isMobile } = useDeviceTypeLayouts();

  const handleBack = () => {
    setActiveTab('');

    if (activeTab) {
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          data: {
            id: PROFILE_DROPDOWN_EVENT_IDS?.BACK?.[activeTab],
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    }
  };

  const handleCloseMenu = () => {
    setProfileDropDownState(null);
    setActiveSection(null);
    handleBack();
    if (onClose) {
      onClose();
    }
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_CLOSE,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  const handleOpenMenu = event => {
    setProfileDropDownState(event.currentTarget);
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.VIEW_CTA,
    });
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_OPEN,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  const middleSectionConfig = [
    {
      icon: 'world',
      title: t(`${TP}.SELECT_LANGUAGE`),
      onClick: () => {
        setActiveTab(TABS_TO_SHOW.LANGUAGE_SELECTOR);
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_LANGUAGE_SELECTOR,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.LANGUAGE_SELECTOR_CTA },
      enabled: true,
    },
    {
      icon: 'settings',
      title: t(`${TP}.ACCOUNT_SETTINGS_GENERAL_TITLE`),
      onClick: () => {
        Router.push(route.ACCOUNT_SETTINGS);
        handleCloseMenu();
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_ACCOUNT_SETTINGS,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.ACCOUNT_SETTINGS_CTA },
      enabled: true,
    },
    {
      icon: 'support-agent',
      title: t(`${TP}.FN_CONTACT_US`),
      onClick: () => {
        Router.push(route.CONTACT_OB);
        handleCloseMenu();
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_CONTACT_US,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      },
      trackingData: { ...trackingData, subComponent: SUB_COMPONENTS.CONTACT_CTA },
      enabled: true,
    },
  ];

  const profileDropdownContent = (
    <div className={classes.profileDropdown__content}>
      <div className={classes.profileDropdown__topSection}>
        <Typography size={14} className={classes.profileDropdown__topSection__title}>
          {userData.email}
          <Typography size={10} className={classes.profileDropdown__topSection__title_badge}>
            {t(`${TP}.TITLE_PRIMARY`)}
          </Typography>
        </Typography>
      </div>
      <div className={classes.profileDropdown__middleSection}>
        {middleSectionConfig.map(
          item =>
            item.enabled && (
              <IconCard
                key={item.title}
                icon={item.icon}
                title={item.title}
                showCircle={false}
                style={classes.iconCardWrapper}
                onClick={item.onClick}
                isHover
                trackingData={item.trackingData}
              />
            ),
        )}
      </div>
      <div className={classes.profileDropdown__allAccessedProfiles}>
        <ProfileCardList profileAccesses={allProfileWithPending} handleProfileCardClick={handleProfileCardClick} />
        <div
          className={classes.profileDropdown__allAccessedProfiles__card}
          onClick={() => {
            track.click({
              ...trackingData,
              component: COMPONENTS.ALL_PROFILES_LIST,
              subComponent: SUB_COMPONENTS.ADD_NEW_PAGE_ACCESS,
            });
            claimNewProfileHandler();
          }}
        >
          <div className={classes.profileDropdown__addProfileWrapper}>
            <div className={classes.profileDropdown__addProfileWrapper_profilePlaceHolder}>
              <SpriteIcon icon="add_circle" />
            </div>
            <Typography size={14} weight="medium" className={classes.profileDropdown__addProfileWrapper_title}>
              {t(`${TP}.ADD_PAGE_ACCESS_BTN_TXT`)}
            </Typography>
          </div>
          <SpriteIcon icon="chevron_right" />
        </div>
      </div>
      <Logout key="logout" user={userData} trackingData={trackingData} />
    </div>
  );

  const activeContent = (
    <ActiveContent
      profileDropdownContent={profileDropdownContent}
      activeTab={activeTab}
      handleBack={handleBack}
      hideBackButton={hideBackButton}
    />
  );

  return (
    <div className={classes.profileDropdown}>
      {!hideActions && (
        <div className={classes.profileDropdown__actions} onClick={handleOpenMenu}>
          <div className={classes.profileDropdown__actions__activeContainer}>
            <div className={classes.profileDropdown__actions__activeWrap}>
              <div className={classes.pageAccessWrap}>
                <Typography
                  className={classes.accessLink}
                  size="13"
                  color={profileDropDownState ? 'link' : 'secondary'}
                >
                  {t(`${TP}.AS_PAGE_ACCESS`)}
                </Typography>
                {Boolean(profileDropDownState) && isDesktop && (
                  <div className={classes.profileDropdown__actions__activeBorder}></div>
                )}
              </div>
              <ProfileMenuButton
                profile={ownerProfile?.profile || profileData}
                isAdmin={isAdmin}
                user={userData}
                showNotification={showNotification}
              />
            </div>
          </div>
        </div>
      )}
      {!isMobile ? (
        <Menu
          anchorEl={profileDropDownState}
          isOpen={Boolean(profileDropDownState)}
          onClose={handleCloseMenu}
          className={classes.profileDropdown__menu}
        >
          {activeContent}
        </Menu>
      ) : (
        <Drawer
          isOpen={Boolean(profileDropDownState)}
          onClose={handleCloseMenu}
          className={classes.profileDropdown__drawer}
        >
          {activeSection && (
            <div className={classes.profileDropdown__title} onClick={handleCloseMenu}>
              <SpriteIcon icon="close" size={17} />
            </div>
          )}
          {activeContent}
        </Drawer>
      )}
    </div>
  );
};

export default ProfileDropdown;
