import React from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';
import Testimonials from 'components/Globals/Testimonials';
import CustomerLogos from 'components/Globals/CustomerLogos';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { ProfileCardList } from 'components/Globals/ProfileDropdown';
import { useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { ENTITY_TYPE, TP } from 'constants/index';
import { CHECKOUT_TYPES, SUBSCRIPTION_PRODUCT_IDS } from 'containers/Accounts/constants';
import { useTranslation } from 'src/i18n';

import classes from './CastingToolPaywall.module.scss';

const CastingToolPaywall = ({ showProfileSelectorForCastingTool, setShowProfileSelectorForCastingTool }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const { setSubscriptionNudgesPopup } = useNotificationPopup();
  const { data: profileAccesses } = useProfileAccesses();
  const onClose = () => {
    setShowProfileSelectorForCastingTool(false);
  };
  const handleProfileCardClick = targetProfile => {
    onClose();
    if (targetProfile.entityType === ENTITY_TYPE.ARTIST || targetProfile.entityType === ENTITY_TYPE.PROFILE) {
      setSubscriptionNudgesPopup({
        isOpen: true,
        isCastingTool: true,
        subscriptionId: SUBSCRIPTION_PRODUCT_IDS.CASTING_TOOL,
        targetProfileIdToUpgrade: targetProfile.id,
      });
    }
    if (targetProfile.entityType === ENTITY_TYPE.ORGANIZATION) {
      setSubscriptionNudgesPopup({
        isOpen: true,
        checkoutType: CHECKOUT_TYPES.AOS,
        subscriptionId: SUBSCRIPTION_PRODUCT_IDS.AOS,
        targetProfileIdToUpgrade: targetProfile.id,
      });
    }
    if (targetProfile.entityType === ENTITY_TYPE.AGENCY || targetProfile.entityType === ENTITY_TYPE.MANAGER) {
      setSubscriptionNudgesPopup({
        isOpen: true,
        checkoutType: CHECKOUT_TYPES.AGENCY,
        subscriptionId: SUBSCRIPTION_PRODUCT_IDS.AGENCY,
        targetProfileIdToUpgrade: targetProfile.id,
      });
    }
  };

  const content = (
    <div className={classes.castingToolPaywallContainer}>
      <div className={classes.closeBtn}>
        <LinkButton
          variant="text"
          leftIcon={<SpriteIcon icon="modal_close" size={16} />}
          onClick={onClose}
          disableHover
          disableUnderline
          stopPropagation
          preventDefault
        ></LinkButton>
      </div>
      <div className={classes.content}>
        <div className={classes.profileSelection}>
          <Typography size="18" weight="medium" className={classes.title}>
            {t(`${TP}.FN_SELECT_PROFILE_TITLE`)}
          </Typography>
          <ProfileCardList
            profileAccesses={profileAccesses?.data || []}
            handleProfileCardClick={handleProfileCardClick}
            styles={{ root: classes.profileCardList }}
            withoutLink
          />
        </div>
      </div>
      <div className={classes.modalFooter}>
        <div className={classes.modalFooter__customerLogo}>
          <Typography size="14" italic color="secondary">
            {t(`FN_TRUSTED_BY_TITLE`)}
          </Typography>
          <CustomerLogos count="4" rowHeight="35" className={classes.trustedByStrip} allowShowAll />
          <div className={classes.testimonialContainer}>
            <Testimonials limit={3} hideTitle />
          </div>
        </div>
      </div>
    </div>
  );
  if (!isMobile) {
    return content;
  }
  return (
    <Modal
      onClose={onClose}
      isOpen={showProfileSelectorForCastingTool}
      styles={{
        modalContainer: classes.modalContainer,
        closeIcon: classes.closeIcon,
        modalRoot: classes.modalRoot,
        drawerRoot: classes.drawerRoot,
        backdrop: classnames(classes.backdrop),
      }}
      allowMobileDrawer
    >
      {content}
    </Modal>
  );
};

export default CastingToolPaywall;
