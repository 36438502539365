import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Testimonials from 'components/Globals/Testimonials';

import PlanCard from 'components/Globals/PlansCard';
import useTracking from 'components/Globals/Analytics';
import Modal from 'components/uiLibrary/Modal';
import ArtistPlanComparison from 'components/Artist/common/ArtistPlanComparison';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Image from 'components/uiLibrary/Image';
import { COMPONENTS, SECTIONS, SUB_COMPONENTS, CUSTOM_EVENT_TYPES } from 'components/Globals/Analytics/constants';
import { useCreateRegistrationMutation } from 'containers/Accounts/queries';
import {
  BASIC_PLAN_FEATURES,
  ARCHIVE_PLAN_FEATURES,
  INDUSTRY_PROFESSIONALS_FEATURES,
  CASTING_TOOL_FEATURES,
  SUBSCRIPTION_PRODUCT_IDS,
  CHECKOUT_TYPES,
} from 'containers/Accounts/constants';
import queries from 'containers/Globals/queries';

import { TP, BASE_PAGE_ROUTES, ENTITY_TYPE } from 'constants/index';

import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';

import { useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import { useTranslation, Trans } from 'src/i18n';
import LogoImage from 'public/images/logo.png';
import CastingToolPaywall from '../../CastingToolPaywall';
import classes from './LoggedInPaywall.module.scss';

const createPlanFeatures = (features, explorePlanToggle = false) =>
  features.map(feature => ({
    ...feature,
    icon: feature.disabled ? 'lock' : feature.icon || 'done',
    disabled: feature.disabled || explorePlanToggle,
  }));

const RightSection = ({ isMobile, t }) => {
  const { data } = useQuery(queries.getHomeNumbers());

  const staticInfo = useMemo(() => {
    const staticInfoKeys = {
      performances: `${TP}.m_PERF`,
      companies: `${TP}.m_OPCOS`,
      festivals: `${TP}.m_FESTIVALS`,
      artists: `${TP}.m_ARTISTS`,
      managers: `${TP}.m_MANAGERS`,
    };
    return Object.entries(staticInfoKeys).map(([key, info]) => ({
      number: data?.data?.[key]?.total,
      info,
    }));
  }, [data]);

  return (
    <>
      {!isMobile && (
        <div className={classes.mainLogoSection}>
          <div className={classes.mainLogoSection__logoImage}>
            <Image src={LogoImage} alt="Operabase Home" height={14} width={140} />
            <Typography size="12" className={classnames(classes.mainLogoSection__logoImage_sinceTitle)}>
              {t(`${TP}.LP_SINCE`)}
            </Typography>
            <Typography size="12">
              <Trans
                i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                ns="NS_APP_GLOBALS"
                components={{
                  ul: <ul className={classes.listWorks} />,
                  li: <li />,
                }}
              />
            </Typography>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className={classes.paywallContent__stats}>
          {data && (
            <div className={classes.paywallContent__stats__inner}>
              {staticInfo.map(item => (
                <Typography size={12} className={classes.paywallContent__stats__inner_statItem} key={item.info}>
                  <Typography size={12} weight="medium" className={classes.paywallContent__stats__inner_statItem_num}>
                    {' '}
                    {item.number?.toLocaleString()}{' '}
                  </Typography>
                  <span className={classnames(classes.paywallContent__stats__inner_statInfo)}>{t(item.info)}</span>
                </Typography>
              ))}
            </div>
          )}
        </div>
      )}
      <div className={classes.separator}></div>
      <div className={classes.testimonials__testimonialsSection}>
        <Typography className={classes.testimonials__title} weight="medium" size="11">
          {t(`${TP}.FN_CONVERSION_TESTIMONIALS`)}
        </Typography>
        <div className={classes.testimonialsContainer}>
          <Testimonials limit={2} hideTitle />
        </div>
      </div>
      <div className={classes.testimonials__trustedSection}>
        <Typography className={classes.testimonials__title} weight="medium" size="11">
          {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}
        </Typography>
        <div className={classes.testimonials__trustedSection_grid}>
          <CustomerLogos
            rows={2}
            className={classes.testimonials__trustedSection_grid_logos}
            rowHeight={40}
            count={6}
            allowShowAll={false}
            gap={2}
          />
        </div>
      </div>
    </>
  );
};

const LoggedInPaywall = ({ onCloseHandler = () => {} }) => {
  const track = useTracking();
  const [explorePlanToggle, setExplorePlanToggle] = useState(false);
  const [isComparePlanModal, setIsComparePlanModal] = useState(false);
  const [showProfileSelectorForCastingTool, setShowProfileSelectorForCastingTool] = useState(false);
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const { ownerProfile } = useProfileAccesses();
  const { profileData, entityType } = useGetTargetProfile();

  const { navigate } = usePageContext();
  const { setSubscriptionNudgesPopup } = useNotificationPopup();
  const hasActiveProfile = profileData?.id || ownerProfile?.id;

  const { mutate: createRegistrationMutation } = useCreateRegistrationMutation({
    onSuccess: response => {
      navigate.to(
        navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.REGISTER,
          queryParams: { id: response?.data?.id },
          onlyLinkProps: true,
        }),
      );
    },
  });

  const createRegistration = () => {
    createRegistrationMutation();
  };

  const basicOnSelectHandler = () => {
    if (hasActiveProfile) {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.BASIC_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CLOSE_PAYWALL,
        },
      });
      onCloseHandler();
    } else {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.BASIC_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CREATE_REGISTRATION,
        },
      });
      createRegistration();
    }
  };

  const archiveOnSelectHandler = () => {
    if (hasActiveProfile) {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.ARCHIVE_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.ARCHIVE_SUBSCRIPTION,
        },
      });
      setSubscriptionNudgesPopup({
        isOpen: true,
        isArchive: true,
        checkoutType: CHECKOUT_TYPES.ARCHIVE,
        subscriptionId: SUBSCRIPTION_PRODUCT_IDS.ARCHIVE_ACCESS,
        targetProfileIdToUpgrade: ownerProfile?.profile?.id || profileData?.id,
      });
    } else {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.ARCHIVE_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CREATE_REGISTRATION,
        },
      });
      createRegistration();
    }
  };

  const industryProfessionalOnSelectHandler = () => {
    const trackAction = (subComponent, actionType) => {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.INDUSTRY_PROFESSIONAL_CARD,
        subComponent,
        meta: {
          actionType,
        },
      });
    };

    if (!hasActiveProfile) {
      trackAction(SUB_COMPONENTS.CONTINUE_CTA, CUSTOM_EVENT_TYPES.CREATE_REGISTRATION);
      return createRegistration();
    }

    const handleProfileAction = (subComponent, callback) => {
      trackAction(subComponent, CUSTOM_EVENT_TYPES.CREATE_SUBSCRIPTION);
      return callback();
    };

    const actions = {
      [ENTITY_TYPE.PROFILE]: () =>
        handleProfileAction(SUB_COMPONENTS.CONTINUE_CTA, () => {
          if (explorePlanToggle) {
            setSubscriptionNudgesPopup({
              isOpen: true,
              checkoutType: CHECKOUT_TYPES.PROFILE,
              subscriptionId: SUBSCRIPTION_PRODUCT_IDS.PROFILE_MONTHLY,
              targetProfileIdToUpgrade: ownerProfile?.profile?.id || profileData?.id,
            });
          } else {
            onCloseHandler();
            navigate.to(
              navigate.getLinkProps({
                entity: profileData || ownerProfile?.profile,
                entityType: ENTITY_TYPE.ARTIST,
                onlyLinkProps: true,
              }),
            );
          }
        }),
    };

    if (!actions[entityType]) {
      trackAction(SUB_COMPONENTS.CONTINUE_CTA, CUSTOM_EVENT_TYPES.CREATE_REGISTRATION);
      return createRegistration();
    }

    return actions[ENTITY_TYPE.PROFILE]();
  };

  const castingToolOnSelectHandler = () => {
    if (hasActiveProfile) {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.CASTING_TOOL_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CASTING_TOOL_SUBSCRIPTION,
        },
      });
      setShowProfileSelectorForCastingTool(true);
    } else {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.CASTING_TOOL_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CREATE_REGISTRATION,
        },
      });
      createRegistration();
    }
  };

  const explorePlanToggleHandler = () => {
    setExplorePlanToggle(prev => !prev);
    track.click({
      section: SECTIONS.PAYWALL_LOGGED_IN,
      component: COMPONENTS.INDUSTRY_PROFESSIONAL_CARD,
      subComponent: SUB_COMPONENTS.EXPLORE_PLAN_TOGGLE,
    });
  };

  const freeVsPaidCTA = () => {
    track.click({
      section: SECTIONS.PAYWALL_LOGGED_IN,
      component: COMPONENTS.INDUSTRY_PROFESSIONAL_CARD,
      subComponent: SUB_COMPONENTS.FREE_VS_PAID_CTA,
    });
    setIsComparePlanModal(true);
  };

  const plans = [
    {
      id: 'basic',
      title: `${TP}.BASIC_TITLE`,
      price: '€0',
      isCurrentPlan: true,
      features: createPlanFeatures(BASIC_PLAN_FEATURES),
      actionButtonVariant: 'text',
      actionText: `${TP}.CONT_FOR_FREE_BTN`,
      onSelect: basicOnSelectHandler,
    },
    {
      id: 'archiveAccess',
      title: `${TP}.ARCHIVE_ACCESS_TITLE`,
      price: '€10',
      description: `${TP}.PER_MONTH_BILLED_ANNUAL`,
      features: createPlanFeatures(ARCHIVE_PLAN_FEATURES),
      actionButtonVariant: 'text',
      actionText: `${TP}.m_CONTINUE`,
      onSelect: archiveOnSelectHandler,
    },
    {
      id: 'industryProfessional',
      title: `${TP}.FN_PRO_INDUSTRY`,
      price: explorePlanToggle ? '€10' : '€0',
      headerCta: explorePlanToggle ? `${TP}.EXPLORE_FREE_PLAN` : `${TP}.EXPLORE_PREMIUM_PLAN`,
      profileNameForSubText: `UP_FOR`,
      profileNameForSubscription: ownerProfile?.profile?.name,
      headerCtaAction: explorePlanToggleHandler,
      description: `${TP}.PER_MONTH_BILLED_ANNUAL`,
      features: createPlanFeatures(INDUSTRY_PROFESSIONALS_FEATURES(!explorePlanToggle)),
      actionButtonVariant: 'text',
      actionText: `${TP}.m_CONTINUE`,
      subActionOnClick: freeVsPaidCTA,
      subActionCtaText: `${TP}.FREE_VS_PAID`,
      onSelect: industryProfessionalOnSelectHandler,
    },
    {
      id: 'castingTool',
      price: '€125',
      title: `${TP}.CASTING_TOOL_PAYWALL_TITLE`,
      description: `${TP}.PER_MONTH_BILLED_ANNUAL`,
      features: createPlanFeatures(CASTING_TOOL_FEATURES),
      actionButtonVariant: 'text',
      actionText: `${TP}.m_CONTINUE`,
      onSelect: castingToolOnSelectHandler,
    },
  ];

  return (
    <div className={classes.loggedInPaywall}>
      <div className={classes.loggedInPaywall__header}>
        <div className={classes.loggedInPaywall__header_closeBtn}>
          <LinkButton
            variant="text"
            leftIcon={<SpriteIcon size={16} icon="modal_close" />}
            onClick={onCloseHandler}
            styles={{ root: classnames(classes.closeBtn) }}
            preventDefault
            stopPropagation
            disableHover
            disableUnderline
          >
            <Typography size="12" weight="medium">
              {t(`${TP}.FN_CLOSE`)}
            </Typography>
          </LinkButton>
        </div>
      </div>
      <div className={classes.loggedInPaywall__content}>
        <div className={classes.loggedInPaywall__left}>
          <div className={classes.loggedInPaywall__leftHeader}>
            <div className={classes.loggedInPaywall__leftHeader__titleWrap}>
              <Typography size={isMobile ? 18 : 32}>
                {t(`${TP}.LOOK_LIKE_TITLE`)}
                <Typography size={isMobile ? 18 : 32} color="orange">
                  {t(`${TP}.POWER_USER`)}
                </Typography>
              </Typography>
              <Typography size={isMobile ? 12 : 14}>{t(`${TP}.GET_ENHANCED_ACCESS_SUBTITLE`)}</Typography>
            </div>
          </div>
          <div className={classes.plans}>
            {plans.map(plan => (
              <PlanCard
                price={plan.price}
                features={plan.features}
                ctaText={plan.ctaText}
                isCurrentPlan={plan.isCurrentPlan}
                title={plan.title}
                actionText={plan.actionText}
                onSelect={plan.onSelect}
                key={plan.id}
                description={plan.description}
                headerCta={plan.headerCta}
                headerCtaAction={plan.headerCtaAction}
                actionButtonVariant={plan.actionButtonVariant}
                subActionOnClick={plan?.subActionOnClick}
                subActionCtaText={plan?.subActionCtaText}
                profileNameForSubscription={plan?.profileNameForSubscription}
                profileNameForSubText={plan?.profileNameForSubText}
              />
            ))}
          </div>
        </div>
        <div className={classes.loggedInPaywall__right}>
          <RightSection isMobile={isMobile} t={t} />
        </div>
      </div>
      <ComparePlanModal isOpen={isComparePlanModal} setIsComparePlanModal={setIsComparePlanModal} />
      {showProfileSelectorForCastingTool && (
        <CastingToolPaywall
          showProfileSelectorForCastingTool={showProfileSelectorForCastingTool}
          setShowProfileSelectorForCastingTool={setShowProfileSelectorForCastingTool}
        />
      )}
    </div>
  );
};

const ComparePlanModal = ({ isOpen, setIsComparePlanModal }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const handleOnClose = () => setIsComparePlanModal(false);
  return (
    <Modal
      isOpen={isOpen}
      styles={{
        modalContainer: classes.comparePlanModalContainer,
        icon: classes.intermediateRedMaskModalContainer__modalIcon,
      }}
      onClose={handleOnClose}
      allowMobileDrawer
    >
      <div className={classes.comparePlanModal}>
        <div className={classes.comparePlanModal__main}>
          <div className={classes.comparePlanModal__header}>
            <LinkButton
              variant="text"
              leftIcon={<SpriteIcon size={16} icon="modal_close" />}
              onClick={handleOnClose}
              styles={{ root: classnames(classes.closeBtn) }}
              preventDefault
              stopPropagation
              disableHover
              disableUnderline
            >
              <Typography size="12" weight="medium">
                {t(`${TP}.FN_CLOSE`)}
              </Typography>
            </LinkButton>
          </div>
          <ArtistPlanComparison onBackClickHandler={handleOnClose} isOpen={isMobile} />
        </div>
      </div>
    </Modal>
  );
};

export default LoggedInPaywall;
