import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import RecentlyVisited from 'components/Globals/RecentlyVisited';

import SEOComponent from 'components/NextSeo';
import Header from 'components/Globals/Layout/Header';
import Footer from 'components/Globals/Layout/Footer';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';

import { IS_CHECKOUT_ACTIVE, IS_IDENTITY_VERIFICATION } from 'constants/cookieConstants';

import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useDialogs } from 'utils/hooks/useDialogs';
import useRenderingType from 'utils/hooks/useRenderingType';
import { getCookie } from 'utils/cookie';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import styles from './PageWrapper.module.scss';
import ScrollToTop from './ScrollToTop';
const AdminPopUp = dynamic(() => import('components/Globals/SubscriptionModels/AdminPopUp'));
const NoCastingToolPopUp = dynamic(() => import('components/Globals/SubscriptionModels/NoCastingToolPopUp'));
const SubscriptionNudges = dynamic(() => import('components/Globals/SubscriptionModels/SubscriptionNudges'));
const CompleteRegistrationPopup = dynamic(() => import('../../../CompleteRegistrationPopup'));
const ProfilePendingAccessPopup = dynamic(() => import('../../../ProfilePendingAccessPopup'));
const RedirectionPopup = dynamic(() => import('../../../RedirectionPopup'));
const SwitchProfilePopUp = dynamic(() => import('components/Globals/SubscriptionModels/SwitchProfilePopUp'));
const NoSubscriptionPopUp = dynamic(() => import('components/Globals/SubscriptionModels/NoSubscriptionPopUp'));
const ReactivateModal = dynamic(() => import('components/Globals/SubscriptionModels/ReactivateModal'));
const CustomerForm = dynamic(() => import('components/Globals/Accounts/CustomerForm'));

const UpgradeToCastingToolPopUp = dynamic(() =>
  import('components/Globals/SubscriptionModels/UpgradeToCastingToolPopUp/UpgradeToCastingToolPopUp'),
);
const ReactivateSubscriptionPopUp = dynamic(() =>
  import('components/Globals/SubscriptionModels/ReactivateSubscriptionPopUp/ReactivateSubscriptionPopUp'),
);

const UpgradeSubscriptionPopUp = dynamic(() =>
  import('components/Globals/SubscriptionModels/SubscriptionUpgradeModal/SubscriptionUpgradeModal'),
);

const AccountsWrapper = dynamic(() => import('components/Globals/Accounts/AccountsWrapper'));
const AudienceOnboarding = dynamic(() => import('components/Globals/Accounts/Common/AudienceOnboarding'));
const SetupPassword = dynamic(() => import('components/Globals/Accounts/Common/SetupPassword/SetupPassword'));
const IdentityVerification = dynamic(() => import('components/Globals/Accounts/IdentityVerification'));
const ApprovedHiddenProfileModal = dynamic(() =>
  import('components/Globals/Accounts/Common/ApproveHiddenProfileModal'),
);
const SwitchProfileModal = dynamic(() => import('components/Globals/Accounts/SwitchProfileModal'));
const AlreadyClaimedModal = dynamic(() => import('components/Globals/Accounts/Common/AlreadyClaimedModal'));
const RequestAccessModal = dynamic(() => import('containers/Managers/RequestAccessModal'));
const ProfileAccessRequestsModal = dynamic(() =>
  import('components/Globals/Accounts/ProfileAccessRequestModal/ProfileAccessRequestsModal'),
);
const ContactFormModal = dynamic(() => import('components/Globals/Accounts/Common/ContactFormModal'));
const ArtistNoAccessModal = dynamic(() => import('components/Globals/Accounts/Common/ArtistNoAccessModal'));
const EmailAlreadyLinked = dynamic(() => import('components/Globals/Accounts/Common/EmailAlreadyLinked'));

const PageWrapper = props => {
  const {
    title,
    children,
    preloadAssets = [],
    seo,
    disableQuery,
    pageQueryList,
    hideNotificationBanner,
    breadcrumbs,
    classes = {},
    hideHeader = false,
    isHeaderStatic,
    hideFooter = false,
    hideScrollToTop = false,
  } = props;

  const { root: rootClassName, container: containerClassName, footer: footerClassName } = classes;
  const router = useRouter();
  const { basePath, entity = {} } = usePageContext();
  const { isLoggedIn } = useAppContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const renderingType = useRenderingType();

  const isCheckoutCookies = getCookie(IS_CHECKOUT_ACTIVE);
  const isOpenIdentityVerificationCookie = getCookie(IS_IDENTITY_VERIFICATION);
  const isOnboarding = router?.query?.onboarding;

  const renderPreloadAssetsLink = useCallback(
    () =>
      preloadAssets?.map((asset, index) => (
        <link key={`preloaded-${index}`} rel="preload" as={asset.type} href={asset.src} />
      )),
    [preloadAssets],
  );

  const pageTitle = useMemo(() => (title ? `${title} - Operabase` : 'Operabase'), [title]);

  const {
    subscriptionNudgesPopup,
    noSubscriptionPopup,
    pendingAccessPopup,
    completeRegistrationPopup,
    archiveDialog,
    upgradeToCastingToolPopup,
    upgradeSubscriptionPopUp,
    reActivatePopUp,
    switchProfilePopUp,
    adminPopUp,
    reactivateSubscriptionPopup,
  } = useNotificationPopup();

  const {
    isLoginDialog,
    resetDialogState,
    isNewUser,
    emailAccountSetting,
    isOpenIdentityVerification,
    isAudienceOnboardingModal,
    approvedHiddenProfile,
    setApprovedHiddenProfile,
    isSwitchProfileModalOpen,
    isRequestAccessModalOpen,
    isProfileAccessRequestsModalOpen,
    displayRedirectionPopup,
    isAlreadyClaimedModal,
    isContactFormModalOpen,
    isArtistNoAccessModalOpen,
    isCustomerFormOpen,
    isEmailAlreadyLinked,
  } = useDialogs();

  Router.events.on('routeChangeStart', () => {
    if (typeof resetDialogState === 'function') {
      resetDialogState();
    }
  });
  return (
    <div className={cn(styles.root, rootClassName)}>
      <Head>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        {/* Apple touch Icon */}
        <link href="/apple-touch-icon-76x76.png" rel="apple-touch-icon" sizes="76x76" />
        <link href="/apple-touch-icon-120x120.png" rel="apple-touch-icon" sizes="120x120" />
        <link href="/apple-touch-icon-152x152.png" rel="apple-touch-icon" sizes="152x152" />

        {/* Splash Screens */}
        <link
          href="/splashscreens/iphone5_splash.png"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphone6_splash.png"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphoneplus_splash.png"
          media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonex_splash.png"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonexr_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonexsmax_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipad_splash.png"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro1_splash.png"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro3_splash.png"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro2_splash.png"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />

        {/* Manifest file - PWA */}
        <link href="/manifest.json" rel="manifest" />
        <meta key="rendering-type" name="rendering-type" content={renderingType} />
        {renderPreloadAssetsLink(preloadAssets)}
      </Head>

      <SEOComponent title={pageTitle} {...seo} disableQuery={disableQuery} pageQueryList={pageQueryList} />

      {!hideHeader && <Header isStatic={isHeaderStatic} hideNotificationBanner={hideNotificationBanner} />}

      <div className={cn(styles.rootContainer, containerClassName)}>
        {breadcrumbs?.length > 0 && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs data={breadcrumbs} {...(!isDesktop && { maxItems: 2 })} />
          </div>
        )}
        {children}
        {adminPopUp?.isOpen && <AdminPopUp isOpen={adminPopUp?.isOpen} />}
        {subscriptionNudgesPopup?.isOpen && <SubscriptionNudges />}
        {noSubscriptionPopup && <NoSubscriptionPopUp isOpen={noSubscriptionPopup} />}
        {completeRegistrationPopup && <CompleteRegistrationPopup isOpen />}
        {pendingAccessPopup && <ProfilePendingAccessPopup isOpen />}
        {isLoginDialog?.isOpen && <AccountsWrapper isNewUser={isNewUser} isModal />}
        {archiveDialog && <NoCastingToolPopUp isOpen={archiveDialog} />}
        {reActivatePopUp.isOpen && <ReactivateSubscriptionPopUp isOpen={reActivatePopUp.isOpen} />}
        {switchProfilePopUp && <SwitchProfilePopUp isOpen={switchProfilePopUp} />}
        {upgradeToCastingToolPopup && <UpgradeToCastingToolPopUp isOpen={upgradeToCastingToolPopup} />}
        {upgradeSubscriptionPopUp.isOpen && !subscriptionNudgesPopup?.isOpen && <UpgradeSubscriptionPopUp isModal />}
        {displayRedirectionPopup && <RedirectionPopup />}
        {(isAudienceOnboardingModal || isOnboarding) && <AudienceOnboarding />}
        {emailAccountSetting.isOpen && <SetupPassword />}
        {(isOpenIdentityVerification || isOpenIdentityVerificationCookie) && <IdentityVerification />}
        {approvedHiddenProfile && (
          <ApprovedHiddenProfileModal profile={approvedHiddenProfile} onClose={() => setApprovedHiddenProfile(false)} />
        )}
        {isCustomerFormOpen && <CustomerForm isModal />}
        {isSwitchProfileModalOpen && <SwitchProfileModal />}
        {isRequestAccessModalOpen && <RequestAccessModal />}
        {isProfileAccessRequestsModalOpen && <ProfileAccessRequestsModal />}
        {isAlreadyClaimedModal && <AlreadyClaimedModal />}
        {isContactFormModalOpen && <ContactFormModal />}
        {isArtistNoAccessModalOpen && <ArtistNoAccessModal />}
        {reactivateSubscriptionPopup?.isOpen && !subscriptionNudgesPopup?.isOpen && <ReactivateModal isModal />}
        {isEmailAlreadyLinked && <EmailAlreadyLinked />}
      </div>
      <ScrollToTop hideScrollToTop={hideScrollToTop} />
      {!entity?.id && !basePath && <RecentlyVisited />}
      {!hideFooter && <Footer className={footerClassName} isLoggedIn={isLoggedIn} />}
    </div>
  );
};

export default PageWrapper;
