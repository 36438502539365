import React from 'react';

import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';

import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import NavLink from '../NavLink';

import classes from './HeaderBottomBlock.module.scss';

const HeaderBottomBlock = ({ onOpenMenu, links = [], trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  if (!isDesktop) {
    return (
      <HorizontalScroller styles={{ arrow: classes.arrow }}>
        <nav>
          <ul className={classes.mobileBottomBlock}>
            {links.map(link => (
              <li className={classes.mobileNavItem}>
                <NavLink
                  key={link.title}
                  trackingData={{
                    ...trackingData,
                    subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
                  }}
                  typographyProps={{ title: { size: 12 } }}
                  {...link}
                />
              </li>
            ))}
          </ul>
        </nav>
      </HorizontalScroller>
    );
  }

  return (
    <div className={classes.bottomBlock}>
      <div className={classes.menu}>
        <NavLink
          title={t(`${TP}.MENU`)}
          dropdown
          typographyProps={{ title: { color: 'primary' } }}
          styles={{ icon: classes.icon }}
          onClick={onOpenMenu}
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.MENU,
          }}
        />
      </div>
      <div className={classes.divider} />
      <HorizontalScroller>
        <nav>
          <ul className={classes.links}>
            {links.map((link, index) => (
              <li className={classes.navItem}>
                <NavLink
                  key={`${link.title}-${index}`}
                  trackingData={{
                    ...trackingData,
                    subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
                  }}
                  typographyProps={{ title: { color: 'primary' } }}
                  options={link?.data}
                  {...link}
                  dropdown
                />
              </li>
            ))}
          </ul>
        </nav>
      </HorizontalScroller>
    </div>
  );
};

export default HeaderBottomBlock;
