import _omit from 'lodash/omit';

import { PAYWALL_TYPES } from 'constants/index';

export const ANALYTICS_SERVICES = {
  GOOGLE_OLD: 'google_old', // NOTE: To be deprecated in 2024-2025
  GOOGLE: 'google', // NOTE: As per new Framework
  ARTS_CONSOLIDATED: 'artsConsolidated', // NOTE: Unified version of Blitzer and FingerPrint
};

export const GOOGLE_OLD_TRACKING_SERVICES = {
  services: [ANALYTICS_SERVICES.GOOGLE_OLD],
};

export const FORCE_ENABLE_ANALYTICS = {
  forceFlush: true,
};

export const EVENT_TYPES = {
  PAGE_VIEW: 'pageView',
  IMPRESSION: 'impression',
  CLICK: 'click',
  SEARCH: 'search',
  ACTION_CALL_BACK: 'actionCallback',
};

export const CUSTOM_EVENT_TYPES = {
  REGISTRATION_SUCCESS: 'registrationSuccess',
  LOGIN_SUCCESS: 'loginSuccess',
  LOGOUT_SUCCESS: 'logoutSuccess',
  PAYMENT_SUCCESS: 'paymentSuccess',
  PAYMENT_FAILURE: 'paymentFailure',
  NO_BILLING_COUNTRY: 'noBillingCountry',
  NO_PAYMENT_METHOD: 'noPaymentMethod',
  CLOSE_PAYWALL: 'closePaywall',
  CREATE_REGISTRATION: 'createRegistration',
  ARCHIVE_SUBSCRIPTION: 'archiveSubscription',
  CREATE_SUBSCRIPTION: 'createSubscription',
  CASTING_TOOL_SUBSCRIPTION: 'castingToolSubscription',
};

export const PAGE_CATEGORY = {
  LISTING: 'listing',
  STUB: 'stub',
  LANDING: 'landing',
  SEARCH: 'search',
};

export const PAGE_TYPES = {
  HOME: 'home',
  ARTIST: 'artist',
  ORGANIZATION: 'organization',
  COMPANY: 'company',
  FESTIVAL: 'festival',
  CASTING: 'casting',
  PRODUCTION: 'production',
  RENTAL: 'rental',
  VIDEO: 'video',
  COMPOSER: 'composer',
  MANAGER: 'manager',
  VENUE: 'venue',
  SEASON: 'season',
  COMPETITION: 'competition',
  WORK: 'work',
  INTRO: 'intro',
  CONTACT: 'contact',
  STATISTIC: 'statistic',
  MAPS: 'maps',
  REGISTER: 'register',
  PRO_AUDIENCE: 'pro-audience',
  PRO_COMPANY_DETAIL: 'pro-company-detail',
  PRO_AGENCY_DETAIL: 'pro-agency-detail',
  PRO_ARTIST_DETAIL: 'pro-artist-detail',
  PRO_ACADEMIA: 'pro-academia',
  PRO_JOURNALIST: 'pro-journalist',
  PRO_ALL: 'pro-all',
  PRIVACY_POLICY: 'privacy-policy',
  COOKIE_POLICY: 'cookie-policy',
  TERMS_CONDITIONS: 'terms-conditions',
  PAYMENT_TERMS: 'payment-terms',
  ACCOUNT_SETTINGS: 'account-settings',
  ANALYTICS_TERMS: 'analytics-terms',
  EXTERNAL_LINK: 'external-link',
  PERMISSION_TEST: 'permission-test',
  SEARCH: 'search',
  RECENT_SEARCH: 'recent-search',
  RECENT_VISIT: 'recent-visit',
  PROFILE_ACCESS_REQUESTS: 'profile-access-requests',
  PAYMENT: 'payment',
  PAYMENT_REACTIVATE: 'payment-reactivate',
  PAYMENT_INTEGRATE: 'payment-integrate',
  LOGIN: 'login',
  LOGIN_SET_PASSWORD: 'login-set-password',
  LOGIN_RESET_PASSWORD: 'login-reset-password',
  ARTICLE: 'article',
  EMAIL_VERIFY: 'email-verify',
  HIGHLIGHTS: 'highlights',
  SEO: 'seo',
  ON_THIS_DAY: 'on-this-day',
  NEW_AND_RARE: 'new-and-rare',
  ERROR_400: 'error-400',
  ERROR_401: 'error-401',
  ERROR_403: 'error-403',
  ERROR_404: 'error-404',
  ERROR_405: 'error-405',
  ERROR_500: 'error-500',
  ERROR_502: 'error-502',
  ERROR_503: 'error-503',
  ERROR: 'error',
};

export const CATEGORIES = {
  SEARCH: 'search',
  RECOMMENDED: 'recommended',
};

export const ACTION_CALLBACK_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const CTAs = {
  ADD_CTA: 'addCTA',
  SEARCH_CTA: 'searchCTA',
  VIEW_CTA: 'viewCTA',
  HELP_CTA: 'helpCTA',
  REQUEST_CTA: 'requestCTA',
  REVIEW_CTA: 'reviewCTA',
  VIEW_MORE_CTA: 'viewMoreCTA',
  NAVIGATE_CTA: 'navigateCTA',
  SAVE_CTA: 'saveCTA',
  SUBMIT_CTA: 'submitCTA',
  UPDATE_CTA: 'updateCTA',
  TICKET_CTA: 'ticketCTA',
  RENTAL_CTA: 'rentalCTA',
  SHARE_CTA: 'shareCTA',
  SEE_ALL_CTA: 'seeAllCTA',
  FILTER_CTA: 'filterCTA',
  CLEAR_CTA: 'clearCTA',
  EDIT_CTA: 'editCTA',
  DELETE_CTA: 'deleteCTA',
  CASTING_TOOL_CTA: 'castingToolCTA',
  VIEW_IN_PUBLIC_SITE_CTA: 'viewInPublicSiteCTA',
  VIEW_IN_CASTING_TOOL_CTA: 'viewInCastingToolCTA',
  OPEN_PERFORMANCE_PAGE_CTA: 'openPerformancePageCTA',
  ACCEPT_CTA: 'acceptCTA',
  REJECT_CTA: 'rejectCTA',
  REVIVAL_CTA: 'revivalCTA',
  REVIEW_LINK: 'reviewLink',
  SEARCH_TAB_CTA: 'searchTabCTA',
  CANCEL_CTA: 'cancelCTA',
  YES_CTA: 'yesCTA',
  NO_CTA: 'noCTA',
  CLOSE_CTA: 'closeCTA',
  INVITE_USER_CTA: 'inviteUserCTA',
  WATCH_OPTIONS_CTA: 'watchOptionsCTA',
  SHARE_ACCESS_CTA: 'shareAccessCTA',
  MANAGE_RECENTLY_VISITED_PAGES_CTA: 'manageRecentlyVisitedPagesCTA',
  WATCH_TRAILER_CTA: 'watchTrailerCTA',
  STREAM_OPTIONS_CTA: 'streamOptionsCTA',
  SOCIAL_MEDIA_CTA: 'socialMediaCTA',
  VIEW_PAGE_CTA: 'viewPageCTA',
  BACK_CTA: 'backCTA',
  ADD_MUSICAL_WORK_CTA: 'addMusicalWorkCTA',
  ADD_COMPOSER_CTA: 'addComposerCTA',
  ADD_PHOTOS_CTA: 'addPhotosCTA',
  ADD_VIDEOS_CTA: 'addVideosCTA',
  PROGRAM_PARTIALLY_ANNOUNCED_CHECKBOX: 'programPartiallyAnnouncedCheckbox',
  HIGHLIGHT_AS_NEW_PRODUCTION_CHECKBOX: 'highlightAsNewProductionCheckbox',
  UPLOAD_CTA: 'uploadCTA',
  NOTIFICATIONS_CTA: 'notificationsCTA',
  LOGOUT_CTA: 'logoutCTA',
  LOGIN_CTA: 'loginCTA',
  COPY_CTA: 'copyCTA',
  CREATE_PROFILE_CTA: 'createProfileCTA',
  ADD_TIME_CTA: 'addTimeCTA',
  CHECKBOX_CTA: 'checkboxCTA',
  REMOVE_LINK_CTA: 'removeLinkCTA',
  ADD_LINK_CTA: 'addLinkCTA',
  RESCHEDULE_CTA: 'rescheduleCTA',
  REMOVE_CTA: 'removeCTA',
  SEND_INVITE_CTA: 'sendInviteCTA',
  ADD_DESCRIPTION_CTA: 'addDescriptionCTA',
  ADD_SYNOPSIS_CTA: 'addSynopsisCTA',
  ADD_BOOKLET_CTA: 'addBookletCTA',
  PUBLISH_CTA: 'publishCTA',
  SUBMIT_CHANGES_CTA: 'submitChangesCTA',
  PROFILE_PREVIEW_CTA: 'profilePreviewCTA',
  SELECT_CTA: 'selectCTA',
  ADD_PRODUCTION_CTA: 'addProductionCTA',
  QUICK_ADD_CTA: 'quickAddCTA',
  CONFIRM_CTA: 'confirmCTA',
  REQUEST_RED_MASK_CTA: 'requestRedMaskCTA',
  REQUEST_REVIEW_APPEAL_CTA: 'requestReviewAppealCTA',
  CONTINUE_CTA: 'continueCTA',
  EDIT_PROFILE_CTA: 'editProfileCTA',
  ADD_NEW_PAYMENT_METHOD_CTA: 'addNewPaymentMethodCTA',
  SUBSCRIPTION_NUDGE_VIEW_ALL_FEATURES: 'subscriptionNudgeViewAllFeaturesCTA',
  COMPARE_PLANS_CTA: 'comparePlansCTA',
  CUSTOMIZE_CTA: 'customizeCTA',
  DECLINE_CTA: 'declineCTA',
  SKIP_CTA: 'skipCTA',
  LOGIN_REGISTER_CTA: 'loginRegisterCTA',
  PROCEED_CTA: 'proceedCTA',
  CONTACT_US_CTA: 'contactUsCTA',
  BROWSE_OPERABASE_CTA: 'browseOperabaseCTA',
  VERIFY_IDENTITY_CTA: 'verifyIdentityCTA',
  ADD_CARD_CTA: 'addCardCTA',
  IGNORE_CTA: 'ignoreCTA',
  CLAIM_YOUR_FREE_MONTH_CTA: 'claimYourFreeMonthCTA',
  FIRST_MONTH_FREE_CTA: 'firstMonthFreeCTA',
  TRY_FREE_FOR_1_MONTH_CTA: 'tryFreeFor1MonthCTA',
  TRY_NOW_FOR_FREE_CTA: 'tryNowForFreeCTA',
  LOGIN_OR_REGISTER_CTA: 'loginOrRegisterCTA',
  TRY_NOW_CTA: 'tryNowCTA',
  UPGRADE_TO_ARTIST_PREMIUM_CTA: 'upgradeToArtistPremiumCTA',
  LANGUAGE_CURRENT_AS_PREFERRED: 'languageCurrentAsPreferred',
  LANGUAGE_SUGGESTED_AS_PREFERRED: 'languageSuggestedAsPreferred',
  LANGUAGE_EXTRA_AS_PREFERRED: 'languageExtraAsPreferred',
  LANGUAGE_SWITCH_TO_PREFERRED: 'languageSwitchToPreferred',
  LANGUAGE_SHOW_EXTRA: 'languageShowExtra',
  COUPON_APPLY: 'couponApply',
  COUPON_REMOVE: 'couponRemove',
  TRY_AGAIN_CTA: 'tryAgainCTA',
  PRO_TOOLS_CTA: 'proToolsCTA',
  VIEW_ALL_CTA: 'viewAllCTA',
};

export const SECTIONS = {
  BLOCKED_TAB: 'blockedTab',
  EDIT_NAVIGATION: 'editNavigation',
  SUBSCRIPTION_NUDGE: 'subscriptionNudge',
  PROFILE_NOTIFICATION_POP_UP: 'ProfileNotificationPopUp',
  ARTIST_SECTION: 'artistSection',
  ARTIST_ENGAGEMENT_BANNER: 'overviewArtistEngagementBanner',
  ARTIST_LIST: 'artistList',
  ARTIST_AND_PROFESSION_LIST: 'artistAndProfessionList',
  PRO_LINKS: 'proLinks',
  PRO_LINKS_BANNER: 'proLinksBanner',
  PRODUCTIONS_AND_PERFORMANCES_SEARCH: 'productionsAndPerformancesSearch',
  FEATURED_VIDEOS: 'featuredVideos',
  DIGITAL_VIDEOS: 'digitalVideos',
  INTRO_SHOWREEL: 'introShowreel',
  DIGITAL_PRESENCE: 'digitalPresence',
  AUDITION_SHOWREEL: 'auditionShowreel',
  FEATURED_PHOTOS: 'featuredPhotos',
  DESCRIPTION_SOURCE: 'descriptionSource',
  VENUES: 'venues',
  HEADER: 'header',
  FOOTER: 'footer',
  FILTERS: 'filters',
  FAQ_MODAL: 'faqModal',
  AGENCY_DETAILS_MODAL: 'agencyDetailsModal',
  PRO_FEATURES: 'proFeatures',
  BREADCRUMBS: 'breadcrumbs',
  PROFILE_INSIGHTS: 'profileInsights',
  RECENTLY_VISITED: 'recentlyVisited',
  GLOBAL_HEADER: 'globalHeader',
  ENTITY_HEADER: 'entityHeader',
  RECOMMENDED_ARTISTS: 'recommendedArtists',
  RECOMMENDED_ORGANIZATIONS: 'recommendedOrganizations',
  PEOPLE_ALSO_VIEWED: 'peopleAlsoViewed',
  POPULAR_TODAY: 'popularToday',
  EXPLORE_MORE: 'exploreMore',
  INVITE_USER: 'inviteUser',
  ORGANIZATION_LISTING: 'organizationListing',
  COMPETITIONS_LISTING: 'competitionsListing',
  VENUES_LISTING: 'venuesListing',
  VENUES_AND_DATE_LISTING: 'venuesAndDateListing',
  WORKS_LISTING: 'worksListing',
  COMPOSER_LISTING: 'composerListing',
  VIDEOS_LISTING: 'videosListing',
  PRODUCTION_LISTING: 'productionListing',
  VALIDATION_DOCS: 'validationDocs',
  RED_MASK_PRODUCTION_LISTING: 'redMaskProductionListing',
  GREY_MASK_PRODUCTION_LISTING: 'greyMaskProductionListing',
  PENDING_PRODUCTION_LISTING: 'pendingProductionListing',
  REQ_IN_PROCESS_PRODUCTION_LISTING: 'inProcessProductionListing',
  CHANGES_IN_PROGRESS_PRODUCTION_LISTING: 'inProgressChangesProductionListing',
  MISSING_PROGRAM_PRODUCTION_LISTING: 'missingProgramProductionListing',
  PRODUCTION_SEARCH: 'productionSearch',
  WORK_LISTING: 'workListing',
  OVERVIEW_CREDITS: 'overviewCredits',
  UPCOMING_PERFORMANCES: 'upcomingPerformances',
  PAST_PERFORMANCES: 'pastPerformances',
  UPCOMING_PERFORMANCES_PHOTOS: 'upcomingPerformancesPhotos',
  PAST_PERFORMANCES_PHOTOS: 'pastPerformancesPhotos',
  UPCOMING_PERFORMANCES_VIDEOS: 'upcomingPerformancesVideos',
  PAST_PERFORMANCES_VIDEOS: 'pastPerformancesVideos',
  UPCOMING_PERFORMANCES_REVIEWS: 'upcomingPerformancesReviews',
  PRODUCTION_REVIEWS: 'productionReviews',
  GENERAL_REVIEWS: 'generalReviews',
  PRODUCTION_WATCH_OPTIONS: 'productionWatchOptions',
  SHARE_ENTITY_MODAL: 'shareEntityModal',
  QUICK_VIEW: 'quickView',
  ABOUT: 'about',
  ORGANIZATION_LOGOS: 'organizationLogos',
  COUNTRY_LIST: 'countryList',
  ORGANIZATION_TYPE_LIST: 'organizationTypeList',
  MANAGER_LIST: 'managerList',
  REPERTOIRE: 'repertoire',
  REPERTOIRE_LIST: 'repertoireList',
  REPERTOIRE_FAQ: 'repertoireFAQ',
  ORGANIZATION_GRAPH_LIST: 'organizationGraphList',
  ROLE_GRAPH_LIST: 'roleGraphList',
  PERFORMANCE_LIST: 'performanceList',
  CASTING_TOOL_HEADER: 'castingToolHeader',
  PERFORMANCES_TODAY: 'performancesToday',
  TESTIMONIALS: 'testimonials',
  TESTIMONIAL_MODAL: 'testimonialModal',
  CASTING_SEARCH_RESULTS: 'castingsSearchResults',
  ALPHABET_LIST: 'alphabetList',
  PROFESSION_LINKS: 'professionLinks',
  MEDIA_WIDGET: 'mediaWidget',
  PERFORMANCE_CREDITS: 'performanceCredits',
  CASTING_TOOL_ROLE_SELECTION: 'castingToolRoleSelection',
  ARTIST_ROSTER: 'artistRosterTable',
  REQUEST_ACCESS_MODAL: 'requestAccessModal',
  RELATED_ENTITIES: 'relatedEntities',
  ASSOCIATED_ORGANIZATIONS: 'associatedOrganizations',
  ARTIST_WORKED_WITH: 'artistWorkedWith',
  ORG_COLLABORATED_WITH: 'orgCollaboratedWith',
  VENUES_PERFORMED_AT: 'venuesPerformedAt',
  REPRESENTATION_AND_CONTACT: 'representationAndContact',
  BIOGRAPHY: 'biography',
  MANAGER_HEADER: 'managerHeader',
  LANGUAGE_SELECTOR: 'languageSelector',
  RENTALS_LISTING: 'rentalsListing',
  EDIT_PROFILE: 'editProfile',
  EDIT_PAGE: 'editPage',
  PRO_TOOLS_CTA: 'proToolsCTA',
  PROGRAM_ABOUT_AND_SYNOPSIS: 'programAboutAndSynopsis',
  DATES_VENUES: 'datesVenues',
  FURTHER_READING_SYNOPSIS_BOOKLET: 'furtherReadingSynopsisBooklet',
  CHECKLIST: 'checklist',
  PAGINATION: 'pagination',
  EDIT_PRODUCTION_INFO: 'editProductionInfo',
  EDIT_PRODUCTION_TICKET_AVAILABILITY: 'editProductionTicketAvailability',
  EDIT_PRODUCTION_POSTER_TRAILER: 'editProductionPosterTrailer',
  EDIT_PRODUCTION_DATE_TIME: 'editProductionDateTime',
  EDIT_PRODUCTION_CAST_CREW: 'editProductionCastCrew',
  EDIT_PRODUCTION_VIDEOS: 'editProductionVideos',
  EDIT_PRODUCTION_PHOTOS: 'editProductionPhotos',
  CUSTOMIZATION: 'customization',
  REVIEW: 'review',
  PRODUCTION_REVIEW: 'productionReview',
  PROFILE_DROPDOWN: 'profileDropdown',
  LOGIN_BANNER: 'loginBanner',
  EDIT_PRODUCTION_BOOKLET: 'editProductionBooklet',
  EDIT_PRODUCTION_REVIEWS: 'editProductionReviews',
  EDIT_PRODUCTION_SYNOPSIS: 'editProductionSynopsis',
  CONTACT_AND_BOX_OFFICE: 'contactAndBoxOffice',
  REVIEWS: 'reviews',
  MANAGEMENT_AND_STAFF: 'managementAndStaff',
  POINT_OF_CONTACT: 'pointOfContact',
  LOGIN_FORM: 'loginForm',
  REGISTRATION_FORM: 'registrationForm',
  MUSICAL_WORKS: 'musicalWorks',
  FUTURE_REPERTOIRE: 'futureRepertoire',
  AGENCY_REPRESENTATION_INFO: 'agencyRepresentationInfo',
  PROFILE_INFO: 'profileInfo',
  EDIT_OVERVIEW: 'editOverview',
  SEASONS: 'seasons',
  ORG_INFO: 'orgInfo',
  CHIP_FILTERS: 'chipFilters',
  HELP_CENTER: 'helpCenter',
  EDIT_PRODUCTION: 'editProduction',
  RETURNING_USER: 'returningUser',
  PAYWALL: 'paywall',
  LOGIN_REGISTER_MODAL: 'loginRegisterModal',
  UPDATE_PASSWORD_MODAL: 'updatePasswordModal',
  START_SUBSCRIPTION_MODAL: 'startSubscriptionModal',
  SUBSCRIPTION_UPGRADE: 'upgrade',
  VERIFY_EMAIL_FROM_SETUP_PASSWORD_MODAL: 'verifyEmailFromSetupPasswordModal',
  DISCLAIMER: 'disclaimer',
  REGISTRATION: 'registration',
  PAYWALL_SOFT: 'paywallSoft',
  PAYWALL_HARD: 'paywallHard',
  PAYWALL_ARCHIVED: 'paywallArchived',
  HERO_BANNER: 'heroBanner',
  INTER_BANNER: 'interBanner',
  CON_FLOW_BANNERS: 'conFlowBanner',
  NOTIFICATION_APP_EXPIRED: 'notificationAppExpired',
  CONTACT_FORM: 'contactForm',
  ALREADY_CLAIMED_MODAL: 'alreadyClaimedModal',
  ARTIST_NO_ACCESS_MODAL: 'artistNoAccessModal',
  HERO_BANNER_DRAWER: 'heroBannerDrawer',
  VIEW_PWA: 'VIEW_PWA',
  AGENCY_ROSTER: 'agencyRoster',
  LANGUAGE_MODAL: 'languageModal',
  REACTIVATE: 'reactivate',
  CHECKOUT: 'checkout',
  ADD_PAYMENT_AND_BILLING_INFO: 'addPaymentAndBillingInfo',
  PAYWALL_LOGGED_IN: 'paywallLoggedIn',
  MENU: 'menu',
  PROFILE_CLAIM: 'profileClaim',
  SALES_PROSPECT_REGISTRATION: 'salesProspectRegistration',
  CASTING_VIEW: 'castingView',
  PAGE_MANAGEMENT: 'pageManagement',
};

export const COMPONENTS = {
  ...CTAs,
  MENU_ITEM: 'menuItem',
  VIEW_CUSTOMER_LIST: 'viewCustomerList',
  FEATURE_COMPARISON_LIST: 'featureComparisonList',
  VIEW_CUSTOMER_TESTIMONIAL: 'viewCustomerTestimonial',
  BLUE_BUTTON: 'blueButton',
  PENDING_ACTION: 'pendingAction',
  FEATURED_REVIEW: 'featuredReview',
  HEADER: SECTIONS.HEADER,
  FOOTER: SECTIONS.FOOTER,
  STICKEY_HEADER: 'stickyHeader',
  TESTIMONIAL_CARD: 'testimonialCard',
  CONTACT: 'contact',
  ALPHABET: 'alphabet',
  ARTIST_CARD: 'artistCard',
  ENTITY_CARD: 'entityCard',
  ARTIST: 'artist',
  CASTING: 'casting',
  MANAGER: 'manager',
  CHIP: 'chip',
  ALL_PRO_TOOLS: 'allProTools',
  AOS: 'aos',
  ARTIST_INDUSTRY_PROFESSIOSNAL: 'artistIndustryProfessional',
  DATES: 'dates',
  MANAGER_AGENCY: 'managerAgency',
  EDUCATION_ACADEMIA: 'educationAcademia',
  JOURNALIST: 'journalist',
  MEDIA_BUTTON: 'mediaButton',
  MULTIPLE_MUSICAL_WORK_DROPDOWN: 'multipleMusicalWorkDropdown',
  ORGANIZATION_CARD: 'organizationCard',
  VENUE_CARD: 'venueCard',
  PREVIEW_CARD: 'previewCard',
  REVIEW_CARD: 'reviewCard',
  REVIEW_MODAL: 'reviewModal',
  REVIEWS: 'reviews',
  VIDEOS: 'videos',
  PHOTOS: 'photos',
  ABOUT: 'about',
  BIOGRAPHY: 'biography',
  HOME: 'home',
  ORGANIZATION_TYPE: 'organizationType',
  ARTIST_DIRECT_CONTACT: 'artistDirectContact',
  ARTIST_DIRECT_CONTACT_MODAL: 'artistDirectContactModal',
  DESIGNATION_DEPARTMENT: 'designationDepartment',
  PRODUCTION_BOOKLET: 'productionBooklet',
  ARTIST_NAME: 'artistName',
  ARTIST_IMAGE: 'artistImage',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION_IMAGE: 'organizationImage',
  CLAIM_OR_CREATE_PROFILE: 'claimOrCreateProfile',
  GLOBAL_SEARCH: 'globalSearch',
  ARTIST_SEARCH: 'artistSearch',
  ALL_PERFORMANCES: 'allPerformances',
  PEFFORMANCE_VENUE: 'performanceVenue',
  PERFORMANCE_DATE: 'performanceDate',
  ORGANIZATION_SEARCH: 'organizationSearch',
  WORK_SEARCH: 'workSearch',
  VENUE: 'venue',
  CAST: 'cast',
  TITLE: 'title',
  LANGUAGE: 'language',
  LIVE_CHAT: 'liveChat',
  LIVE_STREAM: 'liveStream',
  VIDEO_ON_DEMAND: 'videoOnDemand',
  CONTACT_VIA_EMAIL: 'contactViaEmail',
  PRODUCER_SEASON: 'producerSeason',
  VENUE_SEARCH: 'venueSearch',
  PRODUCTION_SLUG: 'productionSlug',
  WORK_CARD: 'workCard',
  EXPANDABLE_CARD: 'expandableCard',
  COHART_SELECTION: 'cohortSelection',
  VIEW_PROFILE_INSIGHTS_CTA: 'viewProfileInsightsCTA',
  EDIT_CTA: 'editCTA',
  FAQ_CTA: 'faqCTA',
  FILTERS: 'filters',
  PAST_PERFORMANCES: 'pastPerformances',
  PRODUCTION: 'production',
  UPCOMING_PERFORMANCES: 'upcomingPerformances',
  FEATURED_PAST_PERFORMANCES: 'featuredPastPerformances',
  FEATURED_PERFORMANCE_VIDEOS: 'featuredPerformanceVideos',
  MANAGERS_SEARCH: 'managersSearch',
  NAVIGATION: 'navigation',
  SEASON_SELECTOR: 'seasonSelector',
  DAY_SEASON_VIEW_SWITCH: 'daySeasonViewSwitch',
  UPCOMING_ALL_DATES_SWITCH: 'upcomingAllDatesSwitch',
  OPERABASE_BUTTON: 'operabaseButton',
  SOCIAL_LINKS: 'socialLinks',
  POLICY_LINKS: 'policyLinks',
  FUTURE: 'future',
  PAST: 'past',
  SCHEDULED: 'scheduled',
  ROW: 'row',
  PAGINATION: 'pagination',
  ACCORDIAN: 'accordian',
  PERFORMANCES_VIDEOS_SEARCH: 'performancesVideosSearch',
  VOD_CARDS: 'vodCards',
  WORK_ROLE_LISTING: 'workRoleListing',
  ADD_ANOTHER_ROLE_CTA: 'addOtherRoleCTA',
  ADD_REVIEW_CTA: 'addReviewCTA',
  ROLE_OPERATOR_MODAL: 'roleOperatorModal',
  PROFESSIONS: 'professions',
  VOICE_TYPES: 'voiceTypes',
  INSTRUMENTALISTS: 'instrumentalists',
  CREW: 'crew',
  ENSEMBLES: 'ensembles',
  CO_PRODUCER: 'coProducer',
  PHOTO: 'photo',
  VIDEO: 'video',
  POSTER: 'poster',
  EDIT_COVER_PHOTO: 'editCoverPhoto',
  INTRO_SHOWREEL: 'introShowreel',
  AUDITION_SHOWREEL: 'auditionShowreel',
  CONTENT: 'content',
  WORK_SELECT_DROPDOWN: 'workSelectDropdown',
  PRODUCTION_WEBSITE: 'productionWebsite',
  PRIMARY_CREDITS: 'primaryCredits',
  APPLIED_FILTERS: 'appliedFilters',
  REPRESENTATION_DETAILS: 'representationDetails',
  VIEW_UPDATE_BTN: 'viewUpdateBtn',
  REQUEST_ACCESS: 'requestAccess',
  ADDITIONAL_AGENTS: 'additionalAgents',
  EDIT_AGENCY: 'editAgency',
  EDIT_CONTACT: 'editContact',
  VIEW_PAGE: 'viewPage',
  ADD_ARTIST_TO_ROSTER: 'addArtistToRoster',
  RECENT_SEARCH: 'recentSearch',
  RECENT_SEARCH_RESULTS: 'recentSearchResults',
  SEARCH_HISTORY: 'searchHistory',
  RECENTLY_VIEWED: 'recentlyViewed',
  SEARCH_RESULTS_CARD: 'searchResultsCard',
  ADVANCE_SEARCH: 'advanceSearch',
  ACTION_STATUS_MODAL: 'actionStatusModal',
  WORK_TYPE_DELETE_MODAL: 'workTypeDeleteModal',
  STAGE_DELETE_MODAL: 'stageDeleteModal',
  PROFILE_PHOTO: 'profilePhoto',
  EDIT_PHOTOS: 'editPhotos',
  MEDIA_CARD: 'mediaCard',
  RENTAL_CARD: 'rentalCard',
  PHOTO_CONFIRM_MODAL: 'photoConfirmModal',
  FUTURE_REPERTOIRE: 'futureRepertoire',
  REPERTOIRE: 'repertoire',
  IMPRESSIONS: 'impressions',
  PAGE_VIEWS: 'pageViews',
  SEARCH_APPEARANCES: 'searchAppearances',
  REQUEST_RED_MASK_PROD: 'requestRedMaskProd',
  CASTING_TOOL_LINK_SECTION: 'CastingToolLinkSection',
  PRODUCTIONS_TICKET_LINK: 'productionsTicketLink',
  HAMBURGER_MENU: 'hamburgerMenu',
  PHOTOS_MODAL: 'photosModal',
  POSTER_MODAL: 'posterModal',
  TRAILER_VIDEO_MODAL: 'trailerVideoModal',
  PRODUCTION_VIDEO_MODAL: 'productionVideoModal',
  ARTIST_WORKED_WITH: 'artistWorkedWith',
  ORG_COLLABORATED_WITH: 'orgCollaboratedWith',
  VENUES_PERFORMED_AT: 'venuesPerformedAt',
  RECOMMENDED_ARTISTS: 'recommendedArtists',
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
  PERFORMANCE_BOX_OFFICE_LINK: 'performanceBoxOfficeLink',
  PERFORMANCE_PHYSICAL_DATES: 'performancePhysicalDates',
  PERFORMANCE_LIVESTREAM_DATES: 'performanceLivestreamDates',
  PERFORMANCE_FULL_VIDEO: 'performanceFullVideo',
  PERFORMANCE_DATES_TABS: 'performanceDatesTabs',
  BIOGRAPHY_MODAL: 'biographyModal',
  PRIVACY_SELECTOR_MODAL: 'privacySelectorModal',
  ADD_ANOTHER_FIELD: 'addAnotherField',
  PHYSICAL_PERFORMANCE_DATES_TABLE: 'physicalPerformanceDatesTable',
  LIVESTREAM_PERFORMANCE_DATES_TABLE: 'livestreamPerformanceDatesTable',
  FULL_VIDEO_PERFORMANCE_DATES_TABLE: 'fullVideoPerformanceDatesTable',
  PERFORMANCE_DATES_EDIT_MODAL: 'performanceDatesEditModal',
  DELETE_PERFORMANCE_MODAL: 'deletePerformanceModal',
  CANCEL_PERFORMANCE_MODAL: 'cancelPerformanceModal',
  RESCHEDULE_PERFORMANCE_MODAL: 'reschedulePerformanceModal',
  ADD_ARTIST_MODAL: 'addArtistModal',
  ADD_ORG_MODAL: 'addOrgModal',
  MUSICAL_WORK_TAB: 'musicalWorkTab',
  PRODUCTION_CAST_TABLE: 'productionCastTable',
  PRODUCTION_CREW_TABLE: 'productionCrewTable',
  PRODUCTION_INSTRUMENTALS_TABLE: 'productionInstrumentalsTable',
  PRODUCTION_ENSEMBLES_TABLE: 'productionEnsemblesTable',
  PRODUCTION_EDIT_CAST_CREW_MODAL: 'productionEditCastCrewModal',
  TRAILER_VIDEO: 'trailerVideo',
  PRODUCTION_VIDEO: 'productionVideo',
  PRODUCTION_PHOTOS: 'productionPhotos',
  PRODUCTION_POSTERS: 'productionPosters',
  EDIT_PRODUCTION_PHOTO_MODAL: 'editProductionPhotoModal',
  PRODUCTION_REVIEW_MODAL: 'productionReviewModal',
  EDIT_BANNER: 'editBanner',
  SHOW_HIDE_TOGGLE: 'showHideToggle',
  REORDER_ENTITY: 'reorderEntity',
  REORDER_ACTION: 'reorderAction',
  POINT_OF_CONTACT_MODAL: 'pointOfContactModal',
  SHARE_ACCESS_MODAL: 'shareAccessModal',
  MENU: 'menu',
  CONTACT_MODAL: 'contactModal',
  REVIEW_ROW: 'reviewRow',
  PRODUCTION_DESCRIPTION: 'productionDescription',
  PRODUCTION_SYNOPSIS: 'productionSynopsis',
  PRODUCTION_DESCRIPTION_ROW: 'productionDescriptionRow',
  PRODUCTION_SYNOPSIS_ROW: 'productionSynopsisRow',
  PRODUCTION_DESCRIPTION_MODAL: 'productionDescriptionModal',
  PRODUCTION_SYNOPSIS_MODAL: 'productionSynopsisModal',
  PRODUCTION_BOOKLET_ROW: 'productionBookletRow',
  PRODUCTION_BOOKLET_MODAL: 'productionBookletModal',
  PRODUCTION_CARD: 'productionCard',
  ADD_PRODUCTION_DIALOG: 'addProductionDialog',
  MUSICAL_WORK_MODAL: 'musicalWorkModal',
  NEW_MUSICAL_WORK_MODAL: 'newMusicalWorkModal',
  PRODUCTION_MODAL: 'productionModal',
  ROLE_SWITCH: 'roleSwitch',
  PRIVACY_SELECTOR: 'privacySelector',
  FUTURE_REPERTOIRE_MODAL: 'futureRepertoireModal',
  AGENCY_REPRESENTATION_MODAL: 'agencyRepresentationModal',
  AGENCY_CARD: 'agencyCard',
  MODAL: 'modal',
  COVER_PHOTO: 'coverPhoto',
  ORG_LOGO: 'orgLogo',
  ENTITY_DESCRIPTION: 'entityDescription',
  VENUES: 'venues',
  ASSOCIATED_ENTITIES: 'associatedEntities',
  ORG_ABOUT_VIDEO: 'orgAboutVideo',
  INTERIOR_PHOTOS: 'interiorPhotos',
  EXTERIOR_PHOTOS: 'exteriorPhotos',
  INSIGHT_BANNER: 'insightBanner',
  SUBSCRIPTION_NUDGE: 'subscriptionNudge',
  PAYMENT_METHODS: 'paymentMethods',
  CREATE_PROFILE: 'createProfile',
  CREATE_PROFESSION: 'createProfession',
  SWITCH_PROFILE: 'switchProfile',
  CREATE_ORGANIZATION: 'createOrganization',
  ADVERTISEMENT: 'advertisement',
  ARTIST_MANAGER_BANNER: 'artistMangerBanner',
  CASTING_TOOL_BANNER: 'castingToolBanner',
  MUSICAL_WORK_SELECTOR: 'musicalWorkSelector',
  COMPOSER_SELECTOR: 'composerSelector',
  PRODUCER_SELECTOR: 'producerSelector',
  CO_PRODUCER_SELECTOR: 'coProducerSelector',
  SEARCH_PROFESSION: 'searchProfession',
  SELECT_PROFESSION: 'selectProfession',
  SELECT_NEW_PROFESSION: 'selectNewProfession',
  SELECT_MATCH_PROFILE: 'selectMatchProfile',
  SELECT_MATCH_PROFILE_FUZZY: 'selectMatchProfileFuzzy',
  SELECT_CREATE_NEW_PROFILE: 'selectCreateNewProfile',
  AOS_BANNER: 'aosBanner',
  ARTIST_UPLOAD_CV: 'artistUploadCV',
  COUNTRY_DROPDOWN: 'countryDropdown',
  TAB_HEADER: 'tabHeader',
  CLAIM_PROFILE: 'claimProfile',
  SUBSCRIPTION_UPGRADE_MODAL: 'subscriptionUpgradeModal',
  LINK_TO_PRODUCTION_PAGE: 'linkToProductionPage',
  LINK_TO_PRODUCTION_PAGE_MODAL: 'linkToProductionPageModal',
  UPLOAD_SCAN_OF_PROGRAMME: 'uploadScanOfProgramme',
  UPLOAD_SCAN_OF_PROGRAMME_MODAL: 'uploadScanOfProgrammeModal',
  UPLOAD_REVIEW: 'uploadReview',
  UPLOAD_REVIEW_MODAL: 'uploadReviewModal',
  UPLOAD_SIGNED_CONTRACT: 'uploadSignedContract',
  ADD_PAYMENT_AND_BILLING_INFO: 'addPaymentAndBillingInfo',
  ADD_FULL_BILLING_ADDRESS: 'addFullBillingAddress',
  CONFIRM_PLAN: 'confirmPlan',
  PAYMENT_SUCCESS: 'paymentSuccess',
  PAYMENT_FAILED: 'paymentFailed',
  ADD_PAYMENT_METHOD: 'addPaymentMethod',
  UPLOAD_SIGNED_CONTRACT_MODAL: 'uploadSignedContractModal',
  INTERMEDIARY_REDMASK_UPGRADE_POPUP: 'intermediaryRedMaskUpgradePopup',
  INLINE: 'inline',
  BASIC_PLAN_CARD: 'basicPlanCard',
  ARCHIVE_PLAN_CARD: 'archivePlanCard',
  INDUSTRY_PROFESSIONAL_CARD: 'industryProfessionalCard',
  CASTING_TOOL_PLAN_CARD: 'castingToolPlanCard',
  PRO_TOOLS: 'proTools',
  HEADER_DROPDOWN: 'headerDropdown',
  WORK_EMAIL_VERIFICATION: 'workEmailVerification',
  PREFERENCES_SELECTION: 'preferencesSelection',
  GET_JOB_INFO: 'getJobInfo',
  WHITE_LISTED_DOMAINS_LIST: 'whiteListedDomainsList',
  ARTIST_CONTACT_INFO: 'artistContactInfo',
  AGENCY_REPRESENTATION_INFO: 'agencyRepresentationInfo',
  ALL_PROFILES_LIST: 'allProfilesList',
};

export const SUB_COMPONENTS = {
  ...CTAs,
  SUBSCRIPTION_NUDGE: 'subscriptionNudge',
  PRO_AGENCIES: 'proAgencies',
  PRO_ARTISTS: 'proArtists',
  PRO_ORGANIZATIONS: 'proOrganizations',
  PRODUCTION_SLUG: 'productionSlug',
  PEFFORMANCE_DATE: 'performanceDate',
  DOWNLOAD_CTA: 'downloadCTA',
  VIDEO_PLAYER: 'videoPlayer',
  MEDIA_CARD: 'mediaCard',
  HEADER: 'header',
  INPUT: 'input',
  REVIEW_CONTENT: 'reviewContent',
  DATE: 'date',
  DAY: 'day',
  ROW: 'row',
  ROLE: 'role',
  CASTING_TOOL_BANNER: 'castingToolBanner',
  MANAGER_PROFILE_BANNER: 'managerProfileBanner',
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
  MODAL: 'modal',
  DRAWER: 'drawer',
  SHOW_HIDE_TOGGLE: 'showHideToggle',
  UPDATE_PROFILE_CTA: 'updateProfileCTA',
  SETUP_ACCOUNT_BUTTON: 'setupAccountButton',
  MANAGE_BUTTON: 'manageCTA',
  SUBSCRIPTION_GIFT_CTA: 'subscriptionGiftCTA',
  LANGUAGE_SELECTOR_CTA: 'languageSelectorCTA',
  ACCOUNT_SETTINGS_CTA: 'accountSettingsCTA',
  CONTACT_CTA: 'contactCTA',
  SOLD_OUT_TOGGLE: 'soldOutToggle',
  ADD_ARTIST_CTA: 'addArtistCTA',
  ADD_ORG_CTA: 'addOrganizationCTA',
  PRODUCTION_NAME: 'productionName',
  PUBLIC: 'public',
  PRIVATE: 'private',
  UPGRADE_TO_PRO: 'upgradeToPro',
  REACTIVATE_SUBSCRIPTION: 'reactivateSubscription',
  DATE_RANGE_FILTER_PLUS_7_DAYS: 'dateRangeFilterPlus7Days',
  DATE_RANGE_FILTER_PLUS_14_DAYS: 'dateRangeFilterPlus14Days',
  DATE_RANGE_FILTER_PLUS_30_DAYS: 'dateRangeFilterPlus30Days',
  DATE_RANGE_FILTER_PLUS_60_DAYS: 'dateRangeFilterPlus60Days',
  DATE_RANGE_FILTER_PLUS_1_YEAR: 'dateRangeFilterPlus1Year',
  MORE_DATES_CTA: 'moreDatesCTA',
  LIGHTBOX_PREVIEW: 'lightboxPreview',
  SUB_FILTERS_CTA: 'subFiltersCTA',
  SUB_FILTER_LANGUAGE: 'subFilterLanguage',
  SUB_FILTER_SUBTITLES: 'subFilterSubtitles',
  SUB_FILTER_SORT_BY: 'subFilterSortBy',
  FORGOT_PASSWORD_BACK_BUTTON: 'forgotPasswordBackButton',
  SOCIAL_MEDIA_BACK_BUTTON: 'socialMediaBackButton',
  REGISTRATION_UPDATE_BACK_BUTTON: 'registrationUpdateBackButton',
  VALIDATE_BACK_BUTTON: 'validateBackButton',
  RETURNING_SOCIAL_AUTH_USER_BACK_BUTTON: 'returningSocialAuthUserBackButton',
  CONTINUE_EMAIL_BUTTON: 'continueEmailButton',
  AUTH_SELECTOR: 'authSelector',
  VERIFY_EMAIL: 'verifyEmailCTA',
  ACCEPT_PRIVACY_CHECKBOX: 'acceptPrivacyCheckbox',
  EMAIL_INPUT: 'emailInput',
  HOSTED_BY_SELECTOR: 'hostedBySelector',
  PASSWORD_INPUT: 'passwordInput',
  SECURITY_CODE_LINK: 'securityCodeLink',
  SHOW_PASSWORD_TOGGLE: 'showPasswordToggle',
  LOGIN_BUTTON: 'loginButton',
  FORGOT_PASSWORD_LINK: 'forgotPasswordLink',
  FORGOT_PASSWORD_SUBMIT: 'forgotPasswordSubmit',
  RESEND_PASSWORD_LINK: 'resendPasswordLink',
  RESEND_CODE: 'resendCodeCTA',
  LOGIN_WITH_EMAIL_OR_SOCIAL_LINK: 'loginWithEmailOrSocialLink',
  LOGIN_WITH_PASSCODE: 'loginWithPasscode',
  VERIFY_EMAIL_PASSCODE: 'verifyEmailPasscode',
  SAVE_PASSWORD: 'savePasswordCTA',
  FIRST_NAME_INPUT: 'firstNameInput',
  LAST_NAME_INPUT: 'lastNameInput',
  START_FREE_TRIAL: 'startFreeTrialCTA',
  PREMIUM_PROFILE_CARDS: 'premiumProfileCards',
  ARTIST_MANAGER_BANNER: 'artistManagerBanner',
  VENUE_DROPDOWN: 'venueDropdown',
  ORGANIZATION_SEARCH: 'organizationSearch',
  ORGANIZATION_TYPE: 'organizationType',
  COUNTRY: 'country',
  CITY: 'city',
  VOICES: 'voices',
  INSTRUMENTS: 'instruments',
  PROFESSIONS: 'professions',
  COMPOSER: 'composer',
  MUSICAL_WORK: 'musicalWork',
  COUNTRY_LIST: 'countryList',
  FILTER_OPTION: 'filterOption',
  FURTHER_READING: 'furtherReading',
  SORT_OPTIONS: 'sortOptions',
  WORK_ROLE: 'workRole',
  YEAR: 'year',
  START_TRIAL: 'startTrial',
  UPGRADE: 'upgrade',
  CHECKOUT: 'checkout',
  REACTIVATE: 'reactivate',
  EXPLORE_PLAN_TOGGLE: 'explorePlanToggle',
  FREE_VS_PAID_CTA: 'freeVsPaidCTA',
  MENU: 'menu',
  CLAIM_ORGANIZATION: 'claimOrganization',
  CLAIM_PROFILE: 'claimProfile',
  CLAIM_AGENCY: 'claimAgency',
  PREFERENCE_CHECKBOX: 'preferenceCheckbox',
  EXPAND_ROW_MAIN: 'expandRowMain',
  CONTACT_INFO_MORE_DETAILS: 'contactInfoMoreDetails',
  ADD_NEW_PAGE_ACCESS: 'addNewPageAccess',
};

export const GOOGLE_ANALYTICS_COMMON_DATA_KEYS = [
  'userProfileTypeId',
  'userSubscriptionProductId',
  'userActiveProfileId',
  'sessionId',
  'utmSource',
  'utmMedium',
  'utmCampaign',
  'utmTerm',
  'utmContent',
  'isInternalUser',
  'buildNumber',
];

export const EVENTS_ENDPOINTS = {
  EVENTS: '/events',
  SYSTEM_PING: '/system/ping',
  GET_ACCESS: 'auth/services/events/get-access',
  REFRESH_ACCESS: 'auth/services/events/refresh-access',
};

export const ACTION_CALLBACK_STEPS = {
  VIEW_RETURNING_USER_MODAL: 'viewReturningUserModal',
  CREATE_USER: 'createUser',
  NEW_REGISTRATION: 'newRegistration',
  EXISTING_USER: 'existingUser',
  SEND_PASSCODE_TO_EMAIL: 'sendPasscodeToEmail',
  LOGIN_WITH_EMAIL: 'loginWithEmail',
  VERIFY_PASSCODE: 'verifyPasscode',
  CREATE_REGISTRATION: 'createRegistration',
  LOGIN_WITH_PASSCODE: 'loginWithPasscode',
  UPDATE_PASSWORD: 'updatePassword',
  REGISTRATION_FIRST_NAME_LAST_NAME_UPDATE: 'registrationFirstNameLastNameUpdate',
  REDIRECT_TO_COHORT_FORM: 'redirectToCohortForm',
  SHOW_LANGUAGE_MODAL: 'showLanguageModal',
  REACTIVATE_SUBSCRIPTION: 'reactivateSubscription',
  INVALID_COUPON: 'invalidCoupon',
  COUPON_APPLIED: 'couponApplied',
  UPGRADE_SUBSCRIPTION: 'upgradeSubscription',
  NEW_SUBSCRIPTION_CHECKOUT: 'newSubscriptionCheckout',
  ADD_EMAIL: 'addEmail',
  CREATE_EMPLOYEE: 'createEmployee',
};

export const PAYWALL_SECTION_MAP = {
  [PAYWALL_TYPES.SOFT_WALL]: SECTIONS.PAYWALL_SOFT,
  [PAYWALL_TYPES.HARD_WALL]: SECTIONS.PAYWALL_HARD,
  [PAYWALL_TYPES.ARCHIVED_WALL]: SECTIONS.PAYWALL_ARCHIVED,
};
