import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useTracking from 'components/Globals/Analytics';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import classes from './IconCard.module.scss';

const IconCard = ({
  icon,
  title,
  subTitle,
  onClick,
  href,
  linkText,
  subTitleTag,
  style,
  showCircle = true,
  isHover = false,
  isProfile = false,
  iconLink,
  trackingData = {},
  withBorder = false,
}) => {
  const track = useTracking();
  const handleClick = e => {
    e.preventDefault();
    if (onClick && typeof onClick === 'function') {
      onClick();
      track.click(trackingData);
    }
  };

  const isIconUrl = urlString => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const renderIcon = () => {
    if (icon && isIconUrl(icon)) {
      return (
        <Image
          src={icon}
          alt={title}
          className={classes.image}
          transformations={TRANSFORMATIONS.PROFILE_IMAGE}
          width={32}
          height={45}
          disableNextImage
        />
      );
    }
    if (isProfile) {
      return (
        <ImageAvatar height={45} width={32} fontSize={14} name={title} className={classes.imagePlaceholder} type="V4" />
      );
    }
    return <SpriteIcon icon={icon || 'person'} />;
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      className={classnames(classes.icon_card, style, {
        [classes.icon_card__withBorder]: withBorder,
      })}
    >
      <div className={classnames(classes.icon_card__left, { [classes.icon_card__left_alignBase]: subTitle })}>
        {' '}
        <div
          className={classnames(classes.icon_card__icon_left, {
            [classes.noBorder]: !showCircle,
            [classes.icon_card__icon_left_override]: isProfile,
          })}
        >
          {renderIcon()}
        </div>
        <div className={classes.icon_card__wrapper}>
          <Typography
            size="14"
            weight="medium"
            className={classnames(classes.icon_card__wrapper_text, { [classes.textHover]: isHover })}
          >
            {title}
          </Typography>
          {(subTitle || subTitleTag) && (
            <Typography size="12" className={classes.icon_card__wrapper_subTitle}>
              {subTitle}{' '}
              {subTitleTag && <span className={classes.icon_card__wrapper_subTitle_tag}>({subTitleTag})</span>}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.icon_card__icon_right}>
        <LinkButton
          styles={{ root: classes.linkButton }}
          variant="text"
          onClick={handleClick}
          trackingData={trackingData}
          rightIcon={<SpriteIcon icon={iconLink || 'chevron_right'} size="18" />}
        >
          {linkText}
        </LinkButton>
      </div>
    </a>
  );
};

IconCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

export default memo(IconCard);
